export const landing = {
    OrUseYourMail: 'Or Use your email',
    SignIn: 'Sign in',
    term_of_use: "I accept the <a href='https://proovee.com/conditionsgenerales/' target='_blank' style='color: inherit !important; ' >general terms of use</a>",
    start_the_test: "START THE TEST",
    firstname: "Firstname",
    lastname: "LastName",
    Email: 'Email',
    next_steps: 'next step',
    OneFieldIncorrect:"One field is incorrect",
    XFieldsIncorrect: "{$x} fields are incorrect",
    SomeFieldsIncorrect: "Some Fields are incorrect",
    VerifEmail: "Check your EMAIL carefully, you will receive your score at this address",
    Participer: "Participer"
}