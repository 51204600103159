import React from 'react';

import { lg } from 'core/locals/Translate.js';
import './ScorePosition.css'; 
import Charts from 'react-apexcharts';

export class ScorePosition extends React.Component {
    static defaultProps = {
        datas: { }
    }
    constructor( props ) {
        super ( props );
        this.state = {
            loaded: false 
        }
    }
    componentDidMount() {
        

        this.setState({ loaded : true })
    }
    render() {
        if( !this.state.loaded ) return false;
        return (<div >
            {this.charts()}
        </div>)
    }
    charts() {
      return <b>Bientôt de retour</b>
        const { parts = { } } = this.props.datas;
        const { averages = false, talent = false } = parts; 
        var d = [10,10,10,10]; 
        var series =  [
          {
            name: 'a', 
            type: 'bar',
            data: [-100, -100, -100, -100]
        },
       /*{
          name: "b", type: 'scatter', 
          data: talent
      },*/
      {
        name: 'c', 
        type: 'bubble',
        data: [  [10, 20, 40] ], //( !averages ? d : averages  )
    }
      ]; 
        var options = {
            
            chart: { 
              type: 'bar',
              toolbar:{
              show: false
          }  },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: false,
              columnWidth: '30%',
             
            },
            buuble: {
              minBubbleRadius: 90
            }
          },
          markers: { 
            size: 40,
           // strokeColors: ['#1E2C4A', '#f66179' ],
            strokeWidth: 3
          },
          dataLabels: {
            enabled: true,
            //enabledOnSeries: [0],
            background: {
              enabled: false
            },
            style: {
              
                colors: ['', '#fff', '#fff']
            },
            formatter: ( val, e ) => {
              //return ''; 
                const { seriesIndex } = e; 
                if( parseInt( val ) < 1 ) return ''; 
                if ( seriesIndex === 1  ) {
                    return val + ' / 20';
                   return val;
                } else if ( seriesIndex === 0 ) return ''; 

              return val + ' / 20';
            }
          },
          xaxis: {
            categories: ['Moins de 15min.', 'De 15 à 30min', 'De 30 à 45min', 'Au delà de 45min.' ],
            type: 'category',
          },
          //colors: ['#f66179', '#1E2C4A', '#f66179'],
          colors: ['#f66179', '#f66179'],
          yaxis: {
              min: 0,
              max: 20
          },
          tooltip: {
            enabled: false,
          },
          legend: {
            show: false
           },
          grid: {
              show: true,
              xaxis: {
                lines: {
                  show: true,
                }
              },
              yaxis: {
                lines: {
                  show: false,
                }
              }
          },
         /* stroke: {
            show: true,
            width: 3,
            colors: ['#fff']
          },*/
        
         
          };




          return <Charts options={options} series={series} type="bar" height={350} />
    }
}