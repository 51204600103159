/* eslint-disable */
import React from "react";

import ApexCharts from "apexcharts";
import Charts from "react-apexcharts";
import "./SkillRepartitions.css";
import { lg } from "core/locals/Translate";

export class SkillRepartitions extends React.Component {
  static defaultProps = {
    data: {},
  };
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      series: [],
      labels: [],
    };
  }
  componentDidMount() {
    this.Load();
  }
  async Load() {
    var series = [];
    var labels = [];
    const { data } = this.props;
    const { skills } = data;
    for (let d of skills) {
      series.push(d.score);
      labels.push(d.name);
    }

    this.setState({ loaded: true, series, labels });
  }

  render() {
    if (!this.state.loaded) return false;
    const { series, labels } = this.state;
    var _labels = [];
    for (let l of labels) {
      if (typeof l === "string") {
        _labels.push(l);
      } else {
        _labels.push(l.join(" "));
      }
    }
    var lcolors = [
      "#FCB719",
      "#1E2C4A",
      "#09B06C",
      "#424242",
      "#F2A385",
      "#824D85",
    ];
    var options = {
      chart: {
        type: "polarArea",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: lcolors,
      tooltip: {
        enabled: true,
        fillSeriesColor: true,
      },
      stroke: {
        colors: ["#FFF"],
        show: false,
      },
      fill: {
        colors: lcolors,
        opacity: 1,
        type: "solid",
      },
      legend: {
        position: "bottom",
        show: false,
      },
      labels,
      yaxis: {
        showAlways: true,
        forceNiceScale: true,
        min: 0,
        max: 20,
        labels: {
          show: true,
          offsetX: 10,
          align: "center",
          formatter: (e) => {
            return e;
          },

          style: {
            fontWeight: "bold",
            fontFamily: "montserrat-extrabold",
            cssClass: "skill-repartition-yaxis",
          },
        },
        floating: true,
        tickAmount: 5,
      },
      plotOptions: {
        polarArea: {
          spokes: {
            strokeWidth: 1,
            connectorColors: "#eee",
          },
        },
      },
    };
    // console.log(options);

    return (
      <div className="skill-repartitions">
        <Charts
          options={options}
          series={series}
          type="polarArea"
          height={350}
        />
        {this.renderLegends(_labels, lcolors)}
      </div>
    );
  }
  renderLegends(labels, lcolors) {
    var tmp = [];
    for (let i in labels) {
      var e = (
        <div className="skill-rp-legends" key={i}>
          <span
            className="skill-rp-color"
            style={{ backgroundColor: lcolors[i] }}
          ></span>
          {labels[i]}
        </div>
      );
      tmp.push(e);
    }
    return <div className="skill-rp-legends-container">{tmp}</div>;
  }
}
