import dateLocale from 'date-fns/locale/en-US';

import { moment as _moment } from './moment.js';
import { textes } from './textes.js';




export const en = {
    iso: 'en',
	textes,
	name: "English",
	_dateLocale: dateLocale,
	_moment
}