/* eslint-disable */
import { Timer } from "../Timer/Timer.js";

class AntiFraudBot {
  malus = 0;
  logs = [];
  started = false;
  references = {};
  constructor() {
    this.started = false;
    this._lost = {};
    this.logs = [];
    this.malus = 0;
  }
  init() {
    this.started = false;
    this._lost = {};
    this.logs = [];
    this.malus = 0;
    this.onEnded = () => {};
    this.references = {};
  }
  startSurvey() {
    if (this.started) return false;
    window.addEventListener("message", (e) => {
      this.ReceiveMessage(e.data);
    });

    window.addEventListener("contextmenu", (event) => {
      this.logs.push({ type: 104, message: "RightClick Detected" });
      this.malus += 0.5;
    });
  }

  async OutOfPageEvent() {
    var outPageTimer = new Timer();
    this.mouseLeave = document.addEventListener("mouseleave", () => {
      if (this.started) {
        outPageTimer.Start();
      }
    });
    this.mouseEnter = document.addEventListener("mouseenter", () => {
      if (this.started) {
        const duration = outPageTimer.Stop();
        // console.log(duration);
        if (duration > 30) {
          this.logs.push({
            type: 101,
            message: "Out Of page for " + " seconds",
          });
          this.malus = 1;
        }
      }
    });
  }
  async ReceiveMessage(data) {
    const { type, ref } = data;
    // console.log("DATA R", data);
    switch (type) {
      case "form-screen-changed":
        if (!this.started) {
          if ((await this.IsQuestion(ref)) === false) return false;
          this.started = true;
          this.QuizzTimer = new Timer();
          this.QuizzTimer.Start();
          this.OutOfPageEvent();
        } else {
          var duree = this.QuizzTimer.Stop("seconds");
          if (duree > 60) {
            this.logs.push({
              type: 102,
              message: duree + " seconds between questions ",
            });
            this.malus += 1;
          }
        }
        break;
      case "form-submit":
        var duration = this.QuizzTimer.Stop("seconds");
        this.started = false;
        // console.log("END", duration);
        this.EndSurvey();
        break;
    }
  }

  async EndSurvey() {
    document.removeEventListener("mouseenter", this.mouseEnter);
    document.removeEventListener("mouseleave", this.mouseLeave);
    this.onEnded({
      malus: this.malus,
      logs: this.logs,
    });
    setTimeout(() => {
      this.init();
    }, 1000);
  }

  /**
   *
   * @param {string} ref
   * @returns {boolean}
   */
  async IsQuestion(ref) {
    let len = Object.values(this.references).length;
    if (len < 1) return true;
    if (typeof this.references[ref] === "undefined") return false;
    if (this.references[ref] === 1001) return true;
    return false;
  }
}

const AntiFraud = new AntiFraudBot();
export { AntiFraud };
