/* eslint-disable */
import React from 'react';
import './SeparatorText.css';

export class SeparatorText extends React.Component {
    static defaultProps = {
        text: '',
    }
    constructor( props ) {
        super( props );
        this.state = {
            loaded: false,
            text: this.props.text 
        }
    }
    componentDidMount() {

    }
    render() {
        return(
            <div className="SeparatorText" >
                <div className="leftzone" ></div>
                <div className="textzone" >{this.state.text}</div>
                <div className="rightzone" ></div>
            </div>
        );
    }
}