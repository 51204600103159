import React from "react";
import { lg } from "core/locals/index.js";

import "./ScorePositionBulles.css";
import Charts from "react-apexcharts";

var maxBubbleDuration = 50;

export class ScorePositionBulles extends React.Component {
  static defaultProps = {
    data: {},
  };
  constructor(props) {
    super(props);
    this.state = {
      loaded: true,
      series: [],
    };
  }

  componentDidMount() {
    this.Load();
  }
  render() {
    if (!this.state.loaded) return false;
    return <div id="ScorePositionBulles-Container">{this.renderGraph()}</div>;
  }

  renderGraph() {
    const { score, duration } = this.props.data;
    var series = this.state.series;
    // console.log("series", series);

    /*var series =  [{
            name: "user",
            data: [ [ parseInt( duration / 60 ) , score ] ]

          },{
            name: "all",
            data: this.GetTest()
          }];*/
    var options = {
      chart: {
        toolbar: { show: false },
        zoom: { enabled: false },
      },
      legend: {
        show: false,
      },
      colors: ["#1E2C4A", "#f66179"],
      xaxis: {
        min: 0,
        max: maxBubbleDuration,
        tickAmount: 10,
        labels: {
          formatter: function (val) {
            if (val < maxBubbleDuration) return parseInt(val);
            return maxBubbleDuration + "+";
          },
        },
        title: {
          text: lg("scorecard.TempsRealisation"),
          style: {
            fontFamily: "montserrat-medium",
          },
        },
      },
      yaxis: {
        min: 0,
        max: 20,
        tickAmount: 5,
        title: {
          text: lg("scorecard.scorerepondants"),
          style: {
            fontFamily: "montserrat-medium",
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      grid: {
        show: true,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    };
    return (
      <div>
        <Charts height={350} type="scatter" series={series} options={options} />
      </div>
    );
  }

  rand(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  GetTest() {
    var tmp = [];
    for (let i = 0; i < 100; i++) {
      tmp.push([this.rand(2, 30), this.rand(0, 18)]);
    }
    tmp.push([31, 6], [31, 17], [31, 0], [31, 15]);

    return tmp;
  }
  async Load() {
    const {
      bubblePositions = [],
      duration,
      score,
      t = false,
    } = this.props.data;
    var series = [];
    if (t) {
      series = [
        {
          name: "user",
          data: [[this.Min(duration), score]],
        },
        {
          name: "all",
          data: this.GetTest(),
        },
      ];
    } else {
      // console.log(bubblePositions);
      var user_key = "";
      if (typeof bubblePositions[0].data[0] !== "undefined") {
        user_key = bubblePositions[0].data[0].join("_");
      }

      // console.log(user_key);
      series[0] = bubblePositions[0];
      series[1] = { data: [], name: "all " };
      for (let other of bubblePositions[1].data) {
        var okey = other.join("_");
        if (okey !== user_key) {
          series[1].data.push(other);
        }
      }

      //series = bubblePositions ;
    }

    this.setState({ loaded: true, series });
  }
  Min(s) {
    if (s < 60) return 0;
    if (s === 60) return 1;
    return parseInt(s / 60);
  }
}
