/* eslint-disable */
import io from "socket.io-client";
import { Device } from "../Device/Device";
import { Events } from "../Events/Events";
import { CONFIG } from "core/config/Config.js";
import { Store } from "../Store/Store";
import axios from "axios";

class WServerClass {
  constructor() {
    this.Reset();
  }
  async Connect(opt = {}) {
    const { query = "", uuid } = opt;
    const setting = {
      ...CONFIG.socket_settings,
      query,
    };
    // console.log(setting);
    const { host, port } = CONFIG.socket_api_connect;
    var url = host;

    this.socket = io(host + ":" + port, setting);
    this.socket.on("connect_error", (m) => {
      // console.log("e", m);
      Events.fire("SOCKET_ERROR", true);
    });
    this.socket.on("connected", ({ siteDatas }) => {
      // console.log("connected width webserver");
      if (this.socketid !== "" && this.socketid !== this.socket.id)
        Events.fire("SOCKET_ID_CHANGED", this.socketid);
      this.socketid = this.socket.id;
      Store.siteDatas = siteDatas;
      this.connected = true;
      Events.fire("SOCKET_CONNECTED", true);
      setTimeout(() => {
        this.KeepAlive();
      }, this.keepAliveDelay);
    });
    this.socket.on("APP-RECIEVE", ({ e, datas }) => {
      Events.fire(e, datas);
    });
  }

  KeepAlive() {
    if (!this.connected) return false;
    this.socket.emit("set/keepconnect/");
    setTimeout(() => {
      this.KeepAlive();
    }, this.keepAliveDelay);
  }

  Reset() {
    this.uid = 0;
    this.socketid = "";
    this.keepAliveDelay = 30000; //30s
    this.connected = false;
    this.OnConnected = () => {};
    this.OnDisconnected = () => {};
    this.ResetEvents();
  }
  ResetEvents() {}
}

const WServer = new WServerClass();
export { WServer };
