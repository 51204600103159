import Pk from "../../../package.json";

export const CONFIG = {
  APP_KEY: "PROOVEE_TALENT",
  BUNDLE_VERSION: "1",
  crypto: {
    key: "43297f767c3cf8de87f848a297a7dae8",
    iv: "0237c67313c9a13956af082f7a440c09",
  },
  // socket_api_connect: { port: 2001, host: 'proovee.com' },
  api_ajax_uri: "https://api.proovee.com",
  api_mode: "ajax", //'socket',
  socket_api_connect: { port: "", host: "api.proovee.com" },
  socket_settings: {
    transports: ["websocket"],
    "force new connection": true,
    reconnection: false, //true,
    reconnectionDelay: 100, //10000,
    reconnectionDelayMax: 60000,
    reconnectionAttempts: "Infinity",
    timeout: 2000, ///10000,
    rejectUnauthorized: false,
    enabledTransports: ["ws", "wss"],
  },

  urls: {
    website: "https://proovee.com",
    images: "https://images.proovee.com",
    images_recruiters: "https://images.proovee.com/recruiters/",
    talent: Pk.homepage,
    currenthost: Pk.homepage,
  },

  lang: "fr",

  linkedin_cfg: {
    client_id: "7874sbn4146ynl",
    client_secret: "2pOLCC9u8RZmZ9VF",
  },
};
