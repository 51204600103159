import qs from "qs";
import axios from "axios";
import { empty } from "../../lib/Tools/Tools.js";
import { CONFIG } from "../../config/Config.js";

export class LinkedInApi {
  constructor() {
    this.access_token = "";
    this.payload = {};

    return new Promise(async (resolve, reject) => {
      // await this.Init(  );
      resolve(this);
    });
  }

  async Init({ code, redirect_uri }) {
    const { client_id, client_secret } = CONFIG.linkedin_cfg;
    if (empty(code) || empty(redirect_uri)) return false;
    const params = {
      grant_type: "authorization_code",
      code,
      redirect_uri,
      client_id,
      client_secret,
    };
    var url = "https://www.linkedin.com/oauth/v2/accessToken";
    const r = await axios({
      method: "post",
      url,
      data: qs.stringify(params),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    const { access_token } = r;
    // console.log(access_token);

    this.access_token = access_token;
  }
  async Get(key) {}
  async Me() {
    const { access_token } = this;
    if (empty(access_token)) return false;
    const params = {
      headers: { Authorization: `Bearer ${access_token}` },
    };
    var url = "https://api.linkedin.com/v2/me";
    const { localizedFirstName, localizedLastName, id } = await axios.get(
      url,
      params
    );

    url =
      "https://api.linkedin.com/v2/emailAddress?q=members&projection=(elements*(handle~))";
    const { elements } = await axios.get(url, params);

    return {
      lastname: localizedLastName,
      firstname: localizedFirstName,
      email: elements[0]["handle~"].emailAddress,
      id,
    };
  }
}
