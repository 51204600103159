import React from 'react';
import { lg } from 'core/locals/index.js'; 

import './ScoreRepartitions.css';
import Charts from 'react-apexcharts';

export class ScoreRepartitions extends React.Component {
    static defaultProps = {
        data: { }
    }
    constructor( props ) {
        super( props );
        this.state = {
           loaded: false ,
           categories: [],
           series: []
        }
    }
    componentDidMount() {
        this.Load(); 

        
    }
    render() {
        if( !this.state.loaded ) return false; 
        return (<div id="ScoreRepartitions-Container" >
           {this.renderLegend()}
            {this.renderGraph()}
        </div>)
    }
    renderLegend() {
        return false;
        /*const { scorePosition } = this.props.data;
        
        return (<div className="ScoreRepartitions-legend"> {lg('scorecard.YourClassement', { percent: scorePosition  })}</div> ); */
    }
    renderGraph() {
        const { score, duration  } = this.props.data;
        var series = [
          {
              data: this.state.series
          }
        ];
       
       


        var options = {
            chart: {
                toolbar: { show: false  },
                zoom: { enabled: false }
            },
            dataLabels: {
                enabled: false,

            },
            annotations: { 
                points: [{
                    seriesIndex: 0,
                    x: this.Group(),
                    marker: { 
                        size: 10,
                        fillColor: "#1E2C4A",
                    },
                    label: {
                        borderColor: '#ccc',
                        textAnchor: 'middle',
                        text: lg('scorecard.YouAreInThisGroup'),
                        style:{
                            color: "#FFF",
                            fontSize: 14,
                            fontFamily: 'montserra-medium',
                            background: '#1E2C4A',

                        }
                    }
                }]
            },
            xaxis: {
                categories: this.state.categories ,
                title: { 
                    text: lg('scorecard.RepartitionByScore'),
                    style: {
                        fontFamily : 'montserrat-medium' 
                    } 
                },
                labels: {
                    formatter: (e) => {
                        const t = lg('scorecard.scoreCardRepatition_' + e ); 
                        return t ; 
                    }
                }
                
            },
            yaxis: {
                show: true,
                title: { 
                    text: lg('scorecard.nbRepondants'),
                    style: {
                        fontFamily : 'montserrat-medium' 
                    } 
                }
            },
            grid: {
                show: true,
                xaxis: {
                  lines: {
                    show: true,
                  }
                },
                yaxis: {
                  lines: {
                    show: false,
                  }
                },
                padding: { top: 20 }
            },
            tooltip: {
                enabled: false,                
            }, 
            colors: ['#f66179'],
            plotOptions: {
                bar: {
                    borderRadius: 4,
                }
            }
            
        }
        return (<div >
            <Charts type="bar" series={series} options={options} height={350} /> 
        </div>)


    
    }
    Group() {
        const { score } = this.props.data;
        if ( score <= 6 ) return '0_5';
        if ( score < 11 && score >= 6 ) return '5_10';
        if ( score >= 11 && score < 16 ) return '10_15';
        return '15_X'; 
    }
    IsInRange( range, value) {
        var min = range[0]; var max = range[1];
        if( min === value  || max === value ) return true; 
        if( value >= min && value <= max ) return true;
        return false; 
    }

    async Load() {
        const { positions = []}  = this.props.data; 
        var series = [];
        var categories = [];
        for( let d of positions ) {
            const { category, nb } = d;
            categories.push( category );
            series.push( nb );
        }
       // console.log( positions );
      //  console.log( series ); 
        this.setState({ loaded: true , categories, series });
    }

    
}