/* eslint-disable */
import React from 'react';
import { ImagePaths } from 'core/config';
import { empty } from '../../lib/Tools/Tools';

export class ProoveeLogo extends React.Component {
    static defaultProps = {
        className: '' ,
        link: false,
        imageStyle: { }, 
    }
    constructor( props ) {
        super( props );
        this.state = {
            loaded: false
        }
    }
    componentDidMount() {

    }
    render() {
        const { className, link , imageStyle  } = this.props;
        if( link !==  false && !empty( link )) {
            return (<div id="proovee-logo" className={className}>
                <a {...link} >
                <img src={ImagePaths.proovee_logo} className="logo-proovee" style={imageStyle} />
                </a>
        </div>)
        } 
        return (
            <div id="proovee-logo" className={className}>
                <img src={ImagePaths.proovee_logo} className="logo-proovee" style={imageStyle}  />
            </div>
        );
    }
}