export const moment =  {
	months : 'january_february_march_april_may_june_july_august_september_october_november_december'.split('_'),
	monthsShort : 'jan._feb._mar._apr._may_june_july._aug._sept._oct._nov._dec.'.split('_'),
	monthsParseExact : true,
	weekdays : 'sunday_monday_tuesday_wednesday_thursday_friday_saturday'.split('_'),
	weekdaysShort : 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
	weekdaysMin : 'Su_Mo_Tu_We_Th_Fr_Sa'.split('_'),
	weekdaysParseExact : true,
	longDateFormat : {
		LT : 'HH:mm',
		LTS : 'HH:mm:ss',
		L : 'YYYY/MM/DD',
		LL : 'YYYY MMMM D',
		LLL : 'YYYY MMMM D HH:mm',
		LLLL : 'dddd YYYY MMMM D HH:mm'
	},
	calendar : {
		sameDay : '[Today at] LT',
		nextDay : '[Tomorrow at] LT',
		nextWeek : 'dddd [at] LT',
		lastDay : '[Yesterday at] LT',
		lastWeek : '[Last] dddd [at] LT',
		sameElse : 'L'
	},
	relativeTime : {
		future : 'in %s',
		past : 'there is %s ago',
		s : 'some seconds',
		m : 'one minute',
		mm : '%d minutes',
		h : 'one hour',
		hh : '%d hours',
		d : 'one day',
		dd : '%d days',
		M : 'one month',
		MM : '%d months',
		y : 'one year',
		yy : '%d years'
	},
	dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,
	ordinal : function (number) {
		return number + (number === 1 ? 'er' : 'e');
	},
	meridiemParse : /PD|MD/,
	isPM : function (input) {
		return input.charAt(0) === 'M';
	},
	// In case the meridiem units are not separated around 12, then implement
	// this function (look at locale/id.js for an example).
	// meridiemHour : function (hour, meridiem) {
	//     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
	// },
	meridiem : function (hours, minutes, isLower) {
		return hours < 12 ? 'PD' : 'MD';
	},
	week : {
		dow : 0, // Sunday is the first day of the week.
		doy : 4  // The week that contains Jan 4th is the first week of the year.
	}

}