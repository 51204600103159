/* eslint-disable */
import React from "react";
import { renderToString } from "react-dom/server";
import ReactCardFlip from "react-card-flip";
import { Card, CardContent, CardActionArea, Grid } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faChartPie,
  faSlidersH,
} from "@fortawesome/free-solid-svg-icons";
import {
  Skills,
  ScoreRadar,
  ScorePosition,
  ScoreMedian,
  ScoreRepartitions,
  ScorePositionBulles,
  SkillRepartitions,
} from "../../components/ScoreCard/index.js";
import { ProoveeLogo } from "../../components/ProoveeLogo/ProoveeLogo.js";
import { ByProoveeLogo } from "../../components/ByProoveeLogo/ByProoveeLogo.js";
import { lg, MomentLocale, Translate } from "../../locals/Translate.js";
import {
  faCalendarAlt,
  faStopwatch,
  faTrophy,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import { strispslashes, IntRand } from "core/lib/index.js";

import { Bubble } from "react-chartjs-2";
import Charts from "react-apexcharts";

import { CONFIG } from "core/config/Config.js";
import moment from "moment";

import gabarit from "assets/gabari-logo-scorecard.png";

import "./card.css";
import "./ScoreCard.css";

import { Helmet } from "react-helmet";
import { API } from "../../lib/Api/Api.js";

var t1 =
  "Etiam pellentesque urna arcu, ut ultrices felis suscipit in. Aliquam congue nisl eu tellus pellentesque, vel sagittis velit mollis";
var t2 =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam odio turpis, malesuada vel nisi sed, tempor rutrum libero. Mauris dictum ornare tellus sit amet volutpat. Nam sed euismod ante. Aenean imperdiet pharetra mauris, quis elementum enim pulvinar at. Cras mollis, eros quis lacinia iaculis, lacus odio blandit libero, a laoreet lacus sapien vel felis. Integer lacinia ligula a tortor vehicula laoreet. Sed vitae volutpat justo. Mauris gravida justo ultrices est rhoncus ornare. Cras lobortis elit eu tristique ullamcorper. Suspendisse nisl est, aliquet sed hendrerit at, auctor ac enim";

function generateData(baseval, count, yrange) {
  var i = 0;
  var series = [];
  while (i < count) {
    var x = Math.floor(Math.random() * (750 - 1 + 1)) + 1;
    var y =
      Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
    var z = Math.floor(Math.random() * (75 - 15 + 1)) + 15;

    series.push([x, y, z]);
    baseval += 86400000;
    i++;
  }
  return series;
}

export class ScoreCard extends React.Component {
  static defaultProps = {
    token: "",
  };
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      notfound: false,
      helmet: {
        title: "Proovee Score Card en test",
        description: "Score Card",
      },
    };
    this._datas = {};
  }
  componentDidMount() {
    this.LoadDatas();
    // console.log(this.props);
  }
  renderNotFoundCard() {
    return (
      <div className="flex">
        <div
          className="loading-wait"
          style={{ fontSize: 15, flexDirection: "column", textAlign: "center" }}
        >
          Votre résultat n'est pas disponible <br />
          Your result is not available
          <p>
            Merci de contacter:{" "}
            <a href="matilto:results@proovee.com">results@proovee.com</a> <br />
            Please contact:{" "}
            <a href="matilto:results@proovee.com">results@proovee.com</a>
          </p>
          <p></p>
          <p>Proovee Team</p>
        </div>
      </div>
    );
  }
  renderWait() {
    return (
      <div className="flex">
        <div className="loading-wait">{lg("common.loading")}</div>
      </div>
    );
  }
  render() {
    if (!this.state.loaded) return this.renderWait();
    if (this.state.notfound) return this.renderNotFoundCard();
    return (
      <div id="score-card-page" className="flex">
        <Helmet>
          <title>{this.state.helmet.title}</title>
          <meta name="description" content={this.state.helmet.description} />
        </Helmet>
        <div className="score-card-content flex">
          {this.renderHead()}

          {this.renderScore()}
          {this.renderMetadata()}

          {this.renderOKNoJSP()}

          {this.renderPositionnementScore()}
          {this.renderTempsPasses()}

          {this.renderRadar()}
          {this.renderSkillRepartions()}
          {this.renderSkills()}
          {this.renderTextes()}
          {this.renderLegacy()}
        </div>
      </div>
    );
  }

  renderHead() {
    //var logo = "PDR1WFD118";
    //var src = CONFIG.urls.images_recruiters + logo + ".png";
    const { questionnaireLogo = "" } = this._datas.questionnaire;
    var src = questionnaireLogo;

    var { questionnaire, repondant } = this._datas;
    var { name: NomQuestionnaire } = questionnaire;
    const { prenom, nom, email } = repondant;

    var logo = renderToString(<ByProoveeLogo className="byProoveeLogo" />);
    var t = lg("scorecard.BilanTest", { logo });

    // BILAN DE <span className="prelative" >COMPÉTENCES <ByProoveeLogo className="byProoveeLogo" /> </span>
    return (
      <div id="score-card-head">
        <div style={{ textAlign: "center" }}>
          {src !== "" && src !== false ? (
            <img style={{ 'max-width': '50%' }} src={src} />
          ) : (
            false
          )}
        </div>
        <Card className="card flex-column">
          <CardContent className="card-content align-center">
            <span className="card-label score-card-title t1">
              <div dangerouslySetInnerHTML={{ __html: t }}></div>
            </span>
          </CardContent>

          <div className="box-line score-card-form-name t2">
            {strispslashes(NomQuestionnaire)}
          </div>
        </Card>

        <Card className=" card">
          <CardContent className="card-content align-center">
            <div className="card-label">{prenom + " " + nom}</div>
            <div className="card-email">{email}</div>
          </CardContent>
        </Card>
      </div>
    );
  }

  renderMetadata() {
    var logo = "PDR1WFD118";
    //var src = CONFIG.urls.images_recruiters + logo + ".png";
    // src = '';

    const { date, duration, level, questionnaire } = this._datas;
    const { nb, companyLogo = "", company } = questionnaire;
    var src = companyLogo;
    var dateFormated = moment(date).format(MomentLocale().longDateFormat.L);
    var durationFormated = this.Duration(duration);

    return (
      <div>
        <div className="card-label card-cat-label">
          {lg("scorecard.GeneralInfos")}
        </div>
        <Card className="card">
          <CardContent className="card-content">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <div className="flex row alignItems">
                  <span className="metadonnees-icons">
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </span>
                  {lg("scorecard.TestDate")}: {dateFormated}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="flex row alignItems">
                  <span className="metadonnees-icons">
                    <FontAwesomeIcon icon={faStopwatch} />
                  </span>
                  {lg("scorecard.TestDuration")}: {durationFormated}
                </div>
              </Grid>

              <Grid item xs={12} sm={6}>
                <div className="flex row alignItems">
                  <span className="metadonnees-icons">
                    <FontAwesomeIcon icon={faTrophy} />
                  </span>
                  {lg("scorecard.Level")}: {level}
                </div>
              </Grid>

              <Grid item xs={12} sm={6}>
                <div className="flex row alignItems">
                  <span className="metadonnees-icons">
                    <i className="fas fa-list-ol"></i>
                  </span>
                  {lg("scorecard.NbQuestions")}: {nb}
                </div>
              </Grid>

              <Grid item xs={12} sm={12}>
                <div className="flex row alignItems">
                  <span className="metadonnees-icons">
                    <FontAwesomeIcon icon={faBuilding} />
                  </span>
                  {lg("scorecard.Company")}:
                  {companyLogo !== false && companyLogo !== "" ? (
                    <img src={src} style={{ height: 20, marginLeft: 10 }} />
                  ) : (
                    company
                  )}
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }
  renderOKNoJSP() {
    const { responses, questionnaire } = this._datas;
    var data = [{ data: [responses.good, responses.bad, responses.jsp] }];
    var _categories = [
      lg("scorecard.GoodResponses"),
      lg("scorecard.BadResponses"),
      lg("scorecard.IDontKnow"),
    ];
    // console.log(_categories);
    var options = {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      colors: ["#1E2C4A", "#1E2C4A", "#1E2C4A"],
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
        style: {
          colors: ["#FFF"],
        },
        formatter: (val, opt) => {
          return val + "%";
        },
      },
      xaxis: {
        categories: _categories,
        labels: {
          formatter: (e) => {
            return e + "%";
          },
        },
      },
      yaxis: {
        min: 0,
        max: 100,
        labels: {
          maxWidth: 250,
          minWidth: 150,
          style: {
            fontSize: "16px",
          },
        },
      },
      tooltip: {
        enabled: false,
      },
    };
    return (
      <div>
        <div className="card-label card-cat-label">
          {lg("scorecard.Responses")}
        </div>
        <Card className="card ">
          <CardContent className="card-content">
            <Charts options={options} series={data} type="bar" height={150} />
          </CardContent>
        </Card>
      </div>
    );
  }
  GetPlaceText() {
    const { placePercent } = this._datas;
    //var texte = lg('scorecard.YourClassement', { percent: scorePosition  });
    /* if( score > 15 ) return lg('scorecard.YourClassementBest25');
        if ( score > 10 && score <= 15) return lg('scorecard.YourClassementBest50');
        if ( score >= 5 && score <= 10) return lg('scorecard.YourClassementLess50'); */

    if (placePercent > 75) return lg("scorecard.YourClassementBest25");
    if (placePercent > 50 && placePercent <= 75)
      return lg("scorecard.YourClassementBest50");
    if (placePercent >= 25 && placePercent <= 50)
      return lg("scorecard.YourClassementLess50");

    return lg("scorecard.YourClassementLess25");
  }
  renderScore() {
    const { score, scorePosition, questionnaire } = this._datas;
    var css = "low";
    if (score >= 11 && score < 16) {
      css = "medium";
    } else if (score > 15) {
      css = "high";
    } else {
      css = "low";
    }
    var place = this.GetPlaceText();
    var { questionnaire_text = "" } = questionnaire;
    /*<p>
                            {place}
                        </p>*/

    return (
      <Card className="card card-splitted score">
        <div
          className={"card-content-handle flex score-value-div " + css}
          style={{}}
        >
          <div
            className="card-label align-center"
            style={{ fontSize: "1.60rem" }}
          >
            {lg("scorecard.YourScore")}
          </div>
          <div
            className="flex box-center"
            style={{ fontWeight: "bold", fontSize: 50 }}
          >
            {score} / 20
          </div>
        </div>
        <CardContent className="card-content flex box-center">
          <div
            dangerouslySetInnerHTML={{
              __html: "<p>" + questionnaire_text + "</p>",
            }}
            className="noteglobale-text"
          ></div>
        </CardContent>
      </Card>
    );
  }

  renderPositionnementScore() {
    return (
      <div>
        <div className="card-label card-cat-label">
          {lg("scorecard.YourPlace")}
        </div>
        <Card className="card">
          <CardContent className="card-content">
            <ScoreRepartitions data={this._datas} />
          </CardContent>
        </Card>
      </div>
    );
  }
  renderTempsPasses() {
    return (
      <div>
        <div className="card-label card-cat-label">
          {lg("scorecard.TempsPasses")}
        </div>
        <Card className="card ">
          <CardContent className="card-content">
            <ScorePositionBulles data={this._datas} />
          </CardContent>
        </Card>
      </div>
    );
  }

  renderPositionnement() {
    // console.log(
    //   generateData(new Date("11 Feb 2017 GMT").getTime(), 12, {
    //     min: 0,
    //     max: 20,
    //   })
    // );

    const DATA_COUNT = 7;
    const NUMBER_CFG = {
      count: DATA_COUNT,
      rmin: 5,
      rmax: 15,
      min: 0,
      max: 100,
    };
    return (
      <div>
        <div className="card-label card-cat-label">
          {lg("scorecard.YourPlace")}
        </div>
        <Card className="card ">
          <CardContent className="card-content">
            <ScorePosition datas={this._datas} />
          </CardContent>
        </Card>
      </div>
    );

    const data = [
      {
        name: "Moi",
        data: [[0, 20, 10]],
      },
    ];
    var options = {
      chart: {
        height: 350,
        type: "scatter",
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        opacity: 0.8,
      },
      title: {
        text: "",
      },
      xaxis: {
        //tickAmount: 12,
        //type: 'category',
        categories: [
          "moins de 15min.",
          "De 15 à 30min",
          "de 30 à 45min",
          "au delà de 45min.",
        ],
        type: "category",
      },
      yaxis: {
        max: 20,
      },
      colors: ["#1E2C4A", "#f66179"],
      tooltip: {
        enabled: false,
      },
    };
    return (
      <div>
        <div className="card-label card-cat-label">
          {lg("scorecard.YourPlace")}
        </div>
        <Card className="card ">
          <CardContent className="card-content">
            <Charts
              options={options}
              series={data}
              type="scatter"
              height={350}
            />
          </CardContent>
        </Card>
      </div>
    );
  }

  renderSkillRepartions() {
    return (
      <div>
        <div className="card-label card-cat-label">
          {lg("scorecard.YourAverage")}
        </div>
        <Card className="card ">
          <CardContent className="card-content">
            <SkillRepartitions data={this._datas} />
          </CardContent>
        </Card>
      </div>
    );
  }
  renderRadar() {
    return false;
    /* var _default = [10,10,10,10,10,10];
        const { nbPostulant, skills } = this._datas;
        var _me = []; var _all = []; var _labels = [];
        for( let d of skills ) {
            _me.push( d.score );
            _all.push( d.average );
            _labels.push( d.name );
        }

        var data = [{
            name: 'moi',
            data: _me,
          }, {
            name: 'all',
            data: ( nbPostulant > 3 ?  _all   : _default ) ,
          }, ];

        return (
        <div>
            <div className="card-label card-cat-label" >{lg('scorecard.YourAverage')} ... test </div>
            <Card className="card " >
        <CardContent className="card-content" >
        <ScoreRadar datas={data} labels={_labels} data={this._datas} />
        </CardContent>

    </Card>
        </div>
        )*/
  }

  renderSkills() {
    const { skills, questionnaire } = this._datas;
    const { nomposte, company, scoreCardConseils = "" } = questionnaire;

    let grids = [];
    for (let skill of skills) {
      if (skill.textes[0] === "") skill.textes[0] = t1;
      if (skill.textes[1] === "") skill.textes[1] = t2;
      var grid = (
        <Grid item xs={12} sm={6} style={{}} key={skill.name}>
          <Skills skill={skill} />
        </Grid>
      );
      grids.push(grid);
    }
    var conseil =
      scoreCardConseils === "" ? (
        lg("scorecard.ConseilText", { nomposte, company }, true)
      ) : (
        <div dangerouslySetInnerHTML={{ __html: scoreCardConseils }}></div>
      );

    return (
      <div style={{ position: "relative" }}>
        <div className="card-label card-cat-label">
          {lg("scorecard.OurRecommandations")}
        </div>
        <Card className="card">
          <CardContent className="card-content ">{conseil}</CardContent>
        </Card>

        <CardContent className="card-content row" style={{ padding: 0 }}>
          <Grid container spacing={3} style={{ marginTop: 12 }}>
            {grids}
          </Grid>
        </CardContent>
      </div>
    );
  }

  renderTextes() {
    const { bottomText = "" } = this._datas.questionnaire;
    // console.log(bottomText);
    if (bottomText === "") return false;

    return (
      <Card className="card ">
        <CardContent className="card-content">
          <div dangerouslySetInnerHTML={{ __html: bottomText }}></div>
        </CardContent>
      </Card>
    );
  }
  renderLegacy() {
    const { company = "", footerLegacy = true } = this._datas.questionnaire;
    var l = "";
    // console.log("legacy", footerLegacy);
    if (footerLegacy === true || footerLegacy === "") {
      l = lg("scorecard.LegacyText", { company }, true);
    } else {
      l = (
        <p
          className="align-center"
          dangerouslySetInnerHTML={{ __html: footerLegacy }}
        ></p>
      );
    }
    AddBlankClass();

    return (
      <Card className="card " style={{ fontSize: 14 }}>
        <CardContent className="card-content">
          <div className="align-center">
            {l}

            <ProoveeLogo
              imageStyle={{ height: 30 }}
              link={{ href: "https://proovee.com", target: "_blank" }}
            />
            <p>
              {lg("scorecard.SocialFollow")} <br />
              <a
                href="https://www.linkedin.com/company/proovee"
                target="_blank"
              >
                LinkedIn
              </a>{" "}
              -
              <a href="https://twitter.com/goproovee" target="_blank">
                Twitter
              </a>
            </p>
          </div>
        </CardContent>
      </Card>
    );
  }
  async LoadDatas() {
    if (this.props.token === "test") return this.LoadTestDatas();

    const { token, form_token = false } = this.props;
    if (form_token !== false) {
      return this.LoadTestDatas(form_token);
    }

    const args = {
      token,
    };
    const d = await API.Get("scorecard/", { args });
    const { etat = 0, scorecard = {} } = d;
    if (etat === 200) {
      if (scorecard === false) {
        var helmet = this.state.helmet;
        helmet.title = "Not Found";
        this.setState({ loaded: true, helmet, notfound: true });
        return false;
      }
      this._datas = scorecard;
      await Translate.SetLang(this._datas.lang);
      var helmet = this.state.helmet;
      helmet.title = lg("scorecard.helmetTitle", {
        title: this._datas.questionnaire.name,
      });
      // console.log(helmet);
      this.setState({ loaded: true, helmet });
      // console.log(this._datas);
    }
  }

  async LoadTestDatas(form_token = false) {
    var s = {};
    var skills = [
      {
        name: ["skill 1"],
        score: 12,
        average: 8,
        textes: [t1, t2],
        label: ["SKILL", "Number 1"],
      },
      { name: ["skill 2"], score: 10, average: 16, textes: [t1, t2] },
      { name: ["skill 3"], score: 8, average: 14, textes: [t1, t2] },
      { name: ["skill 4"], score: 18, average: 12, textes: [t1, t2] },
      { name: ["skill 5"], score: 5, average: 9, textes: [t1, t2] },
      { name: ["skill 6"], score: 15, average: 15, textes: [t1, t2] },
    ];
    if (form_token !== false) {
      const args = { form_token };
      const d = await API.Get("scorecard/test/", { args });
      const { etat = 0, scorecard = {} } = d;
      if (etat === 200) {
        s = scorecard;
        skills = s.skills;
      }
    }

    this._datas = {
      lang: "FR",
      questionnaire: {
        name: "Nom du Questionnaire",
        company: "Veeepee",
        companyLogo:
          "https://images.proovee.com/recruiters/organisateur-veepee.png",
        questionnaireLogo:
          "https://images.proovee.com/questionnaires/Logo-Sales-Academy-veepee.png",
        nb: 20,
        nomposte: "Nom Du Poste",
        bottomText:
          "Aenean interdum, lacus et dapibus fringilla, elit justo lacinia quam, eu posuere neque augue vel erat. " +
          "<br />Nulla vel efficitur arcu. Sed tempor mi id quam placerat, non finibus risus auctor. Etiam lectus ipsum, finibus at velit et, pharetra vestibulum quam. Sed consequat tempor risus sed maximus. Nullam ut semper risus. Nunc at est quis lacus consequat tempor. Proin nec felis magna. In ornare faucibus dignissim. Mauris fringilla tempus neque sit amet consequat. Sed in ex ac nibh lobortis pharetra et non quam. Nullam magna magna, accumsan et dui id, auctor vehicula odio. Vivamus dapibus lectus nisi, vitae aliquet lorem suscipit id. Cras lobortis semper accumsan." +
          "<br /><br />" +
          "Cras malesuada consectetur neque. Nulla non est quis nulla tristique vestibulum eget ac elit. Maecenas vehicula pellentesque ante eu mollis. Fusce quis rhoncus felis. Vivamus blandit faucibus laoreet. Mauris finibus sodales lorem, quis auctor orci. Quisque sodales finibus nisi, et interdum nunc interdum non. Pellentesque at pellentesque mi. Etiam eros orci, maximus at convallis eu, efficitur ac nisl. Phasellus dictum hendrerit facilisis.",
        questionnaire_text:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque congue neque sit amet erat maximus bibendum.  Pellentesque interdum, ipsum ac ornare consequat, orci sem mattis odio, vitae suscipit sapien nisl a lorem.",
        ...s.questionnaire,
      },
      repondant: {
        prenom: "John",
        nom: "Doe",
        email: "johndoe@dodo.com",
      },
      score: IntRand(0, 20),

      scorePosition: 15,
      date: moment(),
      duration: 300, // seconds,
      level: s.level,
      nbPostulant: 12,
      responses: {
        good: 60,
        bad: 10,
        jsp: 30,
      },
      skills,
      bubblePositions: [],
      classement: {},
      placePercent: 80,
      t: true, //test
      positions: [
        { category: "0_5", nb: 10 },
        { category: "5_10", nb: 20 },
        { category: "10_15", nb: 25 },
        { category: "15_X", nb: 6 },
      ],
      parts: {
        averages: [10, 8, 16, 12],
        talent: [-200, 12, -200, -200],
      },
      ...s,
    };
    await Translate.SetLang(this._datas.lang);
    var helmet = this.state.helmet;
    helmet.title = lg("scorecard.helmetTitle", {
      title: this._datas.questionnaire.name,
    });
    // console.log(helmet);
    this.setState({ loaded: true, helmet });
  }
  async LoadFormTestScoreCard(form_token) {
    // const args = {
    //   form_token,
    // };
    // const d = await API.Get("scorecard/test/", { args });
    // const { etat = 0, scorecard = {} } = d;
    // if (etat === 200) {
    //   this._datas = scorecard;
    //   this.setState({ loaded: true });
    //   console.log(this._datas);
    // }
  }

  Duration(v) {
    if (v < 60) return lg("times.seconds", { v });
    if (v === 3600) return lg("times.hour", { v });
    if (v > 3599) {
      v = parseInt(v / 3600);
      return lg("times.hours", { v });
    }
    if (v === 60) return lg("times.hour", { v: 1 });
    v = parseInt(v / 60);
    return lg("times.minutes", { v });
  }
}
