/* eslint-disable */
import  moment from 'moment';

class Timer {
    constructor() {
        this.Start();
    }
    Start() {
        this.time1 = moment();
    }
    Stop( arg = 'seconds' ) {
        this.time2 = moment();
        return this.time2.diff( this.time1, arg );
    }
}

export { Timer };