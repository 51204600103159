import { common } from './textes/common';
import { landing } from './textes/landing'; 
import { scorecard  } from './textes/scorecard';


export const textes = {
    common,
    landing, 
    scorecard
}
