import { Postuler , NotFound, Home, Signin, LandingFrame, ScoreCard  } from '../views/index.js';


const ViewRoutes = [
    { key: 'home', initial: true, component: Home },
    { key: 'signin' , component: Signin },
    { key: 'postuler', component: Postuler, uri: 'postuler/:token' },
    { key: 'LandingFrame', component: LandingFrame }, 
    { key: 'notfound', component: NotFound },
    { key: 'ScoreCard', component: ScoreCard , uri: 'result/:token'}
]

export { ViewRoutes }; 