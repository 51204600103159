/* eslint-disable */
import React from "react";
import prooveePng from "assets/byproovee.png";

export class ByProoveeLogo extends React.Component {
  static defaultProps = {
    style: {},
    className: "",
  };
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }
  compoenntDidMount() {
    this.setState({ loaded: true });
  }
  render() {
    const { className = "" } = this.props;
    return <img src={prooveePng} {...this.props} />;
  }
}
