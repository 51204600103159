export const common = {
    apply: 'Appliquer',
    ok: 'Ok',
    no: 'Non',
    yes: 'Oui',
    pleaseWait: 'Veuillez patienter',
    loading: 'Chargement en cours'
}

export const times = {
    seconds: '{$v} sec.',
    minute: '{$v} min.',
    minutes: '{$v} min.',
    hour: '{$v} heure',
    hours: '{$v} heures',
}