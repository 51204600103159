import { createBrowserHistory } from "history";
import { Events } from "../Events/Events.js";

//const HistoryNav = createBrowserHistory();

class HistoryNavClass {
  constructor() {
    this.onBack = () => {};
    const THIS = this;
    window.onpopstate = function (e) {
      //Events.fire( 'HistoryNav.pop', {} );
      THIS.onBack();
      e.preventDefault();
      return false;
    };
  }
  Push(state, url) {
    history.pushState(state, "", url);
    Events.fire("Historynav.push", { action: "push", url });
  }
}

const HistoryNav = new HistoryNavClass();
export { HistoryNav };
