import dateLocale from 'date-fns/locale/fr';

import { moment as _moment } from './moment.js';
import { textes } from './textes.js';




export const fr = {
    iso: 'fr',
	textes,
	name: "Français",
	_dateLocale: dateLocale,
	_moment
}