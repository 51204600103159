import React from "react";
import "./Router.css";
import { Store } from "core/lib/Store/Store";

class ActionClasses {
  constructor() {
    this._actions = {};
  }
}

const Actions = new ActionClasses();
class Router extends React.Component {
  static defaultProps = {
    drawerPosition: "none",
    drawer: false,
    screenProps: {},
    openSpeed: 300,
    closeSpeed: 300,
    routes: [],
  };
  constructor(props) {
    super(props);
    this.pages = [];
    this.elements = {};
    this.history = [];
    this.state = {
      current: false,
      current_props: {},
      current_route: false,
      back_key: "",
    };
  }
  componentDidMount() {
    const { routes = [] } = this.props;
    this._AddRoutes(routes);
    Actions.pop = (props) => {
      this.GoBack(props);
    };
    Actions.refresh = (props) => {
      this.Refresh(props);
    };
  }

  async _AddRoutes(Routes) {
    if (Routes.length === 0) return false;
    const { CurrentNavPath = "" } = Store;
    var pathCurrent =
      typeof CurrentNavPath.path !== "undefined" ? CurrentNavPath.path : false;
    this.pages = Routes;

    for (let i in Routes) {
      const route = Routes[i];
      const { key, initial = false, menu = true } = route;
      this.elements[key] = { key, element: false, route, props: {}, menu };
      Actions[key] = (props) => {
        this.Navigate(key, props);
      };
      if (initial && pathCurrent === false) {
        this.Navigate(key);
      }
    }
    if (pathCurrent !== false)
      this.Navigate(pathCurrent, CurrentNavPath.queries);
  }

  render() {
    const { children = false } = this.props;
    return (
      <div id="router">
        {this.renderMenu("left")}
        <div id="router-content">
          {this.renderScene()}
          {children}
        </div>
        {this.renderMenu("right")}
      </div>
    );
  }

  renderMenu(position = "left") {
    if (!this.props.drawer) return false;
    const { drawer: Component } = this.props;
    return false;
  }

  renderScene() {
    const { current = false } = this.state;
    if (!current) return false;
    return current;
  }

  async Navigate(key, props = false, clean = false, fromBack = false) {
    const { refresh = false } = props;
    if (typeof this.elements[key] === "undefined")
      return this.Navigate("notfound");
    // console.log(key, this.elements);
    const { route, element, menu = true } = this.elements[key];
    if (this.state.current_route === key && !refresh) {
      //this.DrawerClose();
      return false;
    }
    if (props !== false || element == false) {
      const { component: Component } = route;
      if (props == false) props = {};
      props = { ...this.elements[key].props, ...props };

      props.screenProps = this.screenProps;
      this.elements[key].element = <Component {...props} />;
      this.elements[key].props = props;
    }
    /*if (menu === false ) {
			this.Hide();
		} else {
			this.Show();
		}*/
    //this.DrawerClose();
    this.setState(
      {
        current_route: key,
        current: this.elements[key].element,
        current_props: props,
      },
      () => {}
    );
    if (clean) this.history = [];
    this.history.push(key);
  }

  Clear(key) {
    if (typeof this.eleements[key] === "undefined") return false;
    this.elements[key] = null;
    delete this.elements[key];
  }

  async GoBack(newProps = false) {
    if (this.history.length < 2) return false;
    this.history.pop();
    const last = this.history.pop();
    this.Navigate(last, newProps, dalse, true);
  }

  async Refresh(props = false) {
    const current_route = this.state.current_route;
    const current_props = this.state.current_props;
    Actions[current_route]({ ...current_props, ...props, refresh: true });
  }
}

export { Router, Actions };
