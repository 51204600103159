/* eslint-disable */
import React from "react";
//import { View, Text } from 'react-native';
//import { Colors, Styles, Store, Icon, lg, User, RootUI, ModelCo } from 'core';
//import './Landing.ui.js';
import { LandingCompany, LandingForm } from "./index.js";
import { Device } from "core/lib/Device/Device.js";
import "./Landing.css";
import { Events } from "core/lib/Events/Events.js";

import ReactCardFlip from "react-card-flip";

export class LandingSplitted extends React.Component {
  static defaultProps = {
    form: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      current: 1,
      flipped: false,
    };
    //super.LoadUI( 'Landing' );
  }
  componentDidMount() {
    this.pages = {
      1: (
        <LandingCompany
          continuetBtn={true}
          form={this.props.form}
          onClick={() => {
            this.setState({ current: 2, flipped: true });
          }}
        />
      ),
      2: (
        <LandingForm
          form={this.props.form}
          onBackClick={() => {
            this.setState({ current: 1, flipped: false });
          }}
        />
      ),
    };

    //super.componentDidMount();
    this.setState({ loaded: true });
  }
  render() {
    if (!this.state.loaded) return <div />;
    return this.newRender();
  }

  newRender() {
    return (
      <div className="flex" id="landing-form-card">
        <ReactCardFlip
          isFlipped={this.state.flipped}
          containerStyle={{ height: "100vh" }}
        >
          {this.pages[1]}
          {this.pages[2]}
        </ReactCardFlip>
      </div>
    );
  }
  oldRender() {
    const { current } = this.state;
    return (
      <div id="Postuler" className="flex">
        {this.pages[current]}
      </div>
    );
  }
}
