/* eslint-disable */

import { Store } from '../Store/Store.js';
class EventClass {
    constructor() {
        this.events = { }; this.events.geo = { }; 
    }
    on(key, source,  func) {
		if (typeof  this.events[key] === 'undefined') {
			this.events[key] = {};
		}
		if ( this.events[key] === null ) this.events[key] = { }
		if( typeof this.events[ key ][ source ] !== 'undefined') return true;
		this.events[key][source] = func;
	}
	off(key, source = false ) {
		if( !source) return this.OffEvents(key);
		if( typeof this.events[key] === 'undefined') return true; 
		if (typeof this.events[key][source] == 'undefined') return true;
		let tmp = {};
		for(let i in this.events[key]) {
			if (i !== source) {
				tmp[i] = this.events[key][i];
			}
		}
		this.events[key] = tmp;
		return this;
	}
    OffEvents(key) {
		if( typeof this.events[key] === 'undefined') return false;

		this.events[key] = null;
		delete this.events[key];
		return this;
	}
	fire(key, values) {
		if (this.events[key] === 'undefined') {
			return false;
		}
		for(let i in this.events[key]) {
			this.events[key][i](values);
		}
	}
}

const Events = new EventClass();
export { Events };