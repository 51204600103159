import React from "react";
import { API } from "../../lib/Api/Api";
import { AntiFraud } from "../../lib/AntiFraud/AntiFraud.js";
import "./LandingFrame.css";
import { ProoveeLogo } from "../../components/ProoveeLogo/ProoveeLogo.js";

export class LandingFrame extends React.Component {
  static defaultProps = {
    user: {},
    form: {},
    testMode: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      cleI: "",
    };
  }
  componentDidMount() {
    // console.log(this.props);
    this.GetCleI();
  }
  render() {
    if (!this.state.loaded) return this.L();
    return (
      <div id="page-lanfding-frame">
        {this.head()}
        {this.frame()}
        {this.footer()}
      </div>
    );
  }
  L() {
    return (
      <div className="flex box-center" style={{ flex: 1, textAlign: "center" }}>
        <h5>Chargement en cours...</h5>
      </div>
    );
  }
  head() {
    return (
      <div id="talent-frame-head">
        <ProoveeLogo imageStyle={{ width: 200 }} />
      </div>
    );
  }
  footer() {
    return false;
    // return (
    //   <div id="talent-frame-footer">
    //     <div className="flex"></div>
    //     <span id="copy">Proovee - tous droits réservés &copy; 2020 - 2021</span>
    //   </div>
    // );
  }
  frame() {
    const { utoken, uid, nom, prenom, email } = this.props.user;
    const { uid: recruiterId, id: qid, typeform, token } = this.props.form;
    // console.log(this.props.form);
    const { cleI } = this.state;
    var queries = {
      clei: cleI,
      postulant_id: uid,
      postulant_nom: nom,
      postulant_prenom: prenom,
      postulant_email: email,
    };
    queries = this.ToQueries(queries);

    var url = "https://proovee.typeform.com/to/" + typeform + "?" + queries;

    return (
      <div id="talent-frame">
        <script
          type="text/javascript"
          src="https://embed.typeform.com/embed.js"
        ></script>
        <div id="proovee-frame-container" className="flex">
          <iframe
            scrolling="no"
            border="0"
            src={url}
            onLoad={() => this.iFrameOnLoaded()}
            ref={(e) => (this.iframe = e)}
            style={{
              border: "0 none",
              /*width: '100%' ,*/ height: "100%",
              margin: "0 2px 2px 2px",
            }}
            allow="camera; microphone; autoplay; encrypted-media;"
            sandbox="allow-same-origin allow-scripts"
            id="typeform-full"
          ></iframe>
        </div>
      </div>
    );
  }

  ToQueries(source) {
    var tmp = [];
    for (let i in source) {
      tmp.push(i + "=" + source[i]);
    }
    return tmp.join("&");
  }
  async iFrameOnLoaded() {
    const { references = {} } = this.props.form;
    AntiFraud.references = references;
    AntiFraud.onEnded = (antiFraud) => this.EndQuestionnaire(antiFraud);
    AntiFraud.startSurvey();
  }

  async GetCleI() {
    if (this.props.testMode) {
      this.setState({
        loaded: true,
        cleI: false,
      });

      return false;
    }
    const { id } = this.props.form;
    const { uid } = this.props.user;
    const args = {
      qid: id,
      uid,
    };
    // console.log(args);
    const { etat, clei = false } = await API.Get("clei/", { args });
    // console.log(clei);
    if (etat === 200 && clei !== false) {
      this.Start(clei);
    }
  }
  async Start(clei) {
    const { id } = this.props.form;
    const { uid } = this.props.user;
    const args = {
      form_id: id,
      postulant_id: uid,
      recruteur_id: this.props.form.uid,
      clei,
    };

    const { etat } = await API.Set("questionnaire/start/", { args });
    if (etat === 200) {
      this.setState({
        loaded: true,
        cleI: clei,
      });
    }
  }

  async EndQuestionnaire(antiFraud) {
    const { form, user } = this.props;
    const { cleI } = this.state;
    const args = {
      form_id: form.id,
      postulant_id: user.uid,
      clei: cleI,
      antiFraud,
    };
    const { etat = 0 } = await API.Set("questionnaire/ended/", { args });
  }
}
