export const common = {
    apply: 'Apply',
    ok: 'Ok',
    no: 'No',
    yes: 'Yes',
    pleaseWait: 'Please wait',
    loading: 'Loading'
}

export const times = {
    seconds: '{$v} sec.',
    minute: '{$v} min.',
    minutes: '{$v} min.',
    hour: '{$v} h.',
    hours: '{$v} h.',
}