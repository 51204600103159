/* eslint-disable */
import React from 'react';
import { ProoveeLogo } from '../../components/ProoveeLogo/ProoveeLogo.js';

export class NotFound extends React.Component {
    static defaultProps = {

    }
    constructor( props ) {
        super( props );
        this.state = {
            loaded: false
        }
    }
    componentDidMount() {
        this.setState({ loaded: true });
    }
    render() {
        return(
            <div  className="flex box-center">
                <ProoveeLogo link={{ href: "https://proovee.com" }}  />
                <h4>Cette page n'est pas accessible</h4>
                
            </div>
        );
    }
}