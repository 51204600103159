

export const url_paths = [
    { reg: "/postuler/:token", path: "postuler" },
    { reg: "/form/:token", path: "postuler" },
    { reg: '/signin/:mode', path: 'signin'},
    { reg: '/result/:token', path : 'ScoreCard'},

    { reg: "/form/otsukaresama/:token", path: "postuler" , posts: {  testMode: true  }},
    { reg: '/result/test/:form_token', path : 'ScoreCard'}
]