

export const landing = {
    OrUseYourMail: 'OU UTILISEZ VOTRE EMAIL',
    SignIn: 'INSCRIVEZ-VOUS AVEC',
    term_of_use: "J'accepte les conditions <a href='https://proovee.com/conditionsgenerales/' target='_blank' style='color: inherit !important; ' >générales d'utilisation</a>" ,
    start_the_test: "COMMENCER LE QUESTIONNAIRE",
    firstname: "Prénom",
    lastname: "Nom",
    Email: 'Email',
    next_steps: 'étape suivante',
    OneFieldIncorrect:"Un champ est incorrect",
    XFieldsIncorrect: "{$x} champs sont incorrect",
    SomeFieldsIncorrect: "Plusieurs champs sont incorrect",
    VerifEmail: "Vérifier bien votre EMAIL, vous recevrez votre score à cette adresse",
    Participer: "Participer"
}