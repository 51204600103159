import { Store } from "../Store/Store.js";
import { empty, NoSpace } from "../Tools/Tools.js";

import pathToRegexp from "path-to-regexp";
import { HistoryNav } from "./HistoryNav.js";

class BrowserNavClass {
  constructor() {
    this._redirect_paths = [];
    this._location_root = "";
  }
  async ParseLocation() {
    let out = {};
    const { pathname, search } = window.location;
    out.pathname = pathname;
    out.search = search;
    out.path = "";

    let params = new URLSearchParams(search.substr(1));
    out.queries = {};
    for (var param of params) {
      let line = {};
      out.queries[param[0]] = param[1];
    }

    let pathExplode = pathname.substring(1).split("/");
    out.paths = pathExplode;

    let pathsRoutes = this._redirect_paths;
    for (let i in pathsRoutes) {
      let pr = pathsRoutes[i];
      const { reg = "(.*)", posts = {} } = pr;
      let t = await this.GetQuery(pathname, reg);
      if (t !== false) {
        out.path = pr.path;

        out.queries = { ...out.queries, ...t, ...posts };

        return out;
      }
    }

    return out;
  }
  async GetQuery(url, reg = false) {
    if (reg === false || reg === "(.*)") return {};
    let keys = [];

    const regexp = pathToRegexp(reg, keys);
    let parsed = regexp.exec(url);
    if (parsed === null) return false;
    let params = {};
    for (let i in keys) {
      let x = parseInt(i + 1);
      params[keys[i].name] = parsed[x];
    }
    return params;
  }
  async AddRedirectPaths(paths) {
    this._redirect_paths = paths;
    return this;
  }
  async SetLocationBar(urlsource, params = {}) {
    //console.trace( 'aaaaa', urlsource );
    let url = urlsource;
    if (!empty(url)) {
      for (let i in params) {
        const value = params[i];
        if (typeof value === "string" || typeof value === "number") {
          url = url.replace("{" + i + "}", value);
        }
      }
    }
    url = Store.locationRoot + "/" + url;

    //history.pushState({ url } , '', url );
    HistoryNav.Push({ url }, url);
  }
}

const BrowserNav = new BrowserNavClass();
export { BrowserNav };
