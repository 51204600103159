import React from "react";
import { Store } from "../Store/Store.js";
import { Events } from "../Events/Events.js";
import { Cache } from "../Cache/Cache.js";
import { CONFIG } from "../../config/Config.js";

class AjaxClass {
  constructor() {
    this.Reset();
  }
  async Connect(opt = {}) {
    const { query, uuid } = opt;
    const params = {
      uuid,
    };
    const { api_ajax_uri } = CONFIG;
    var d = await fetch(api_ajax_uri + "/co/", {
      method: "POST",
      body: JSON.stringify(params),
      headers: { "Content-Type": "application/json" },
    });
    d = await d.json();
    // console.log(d);
    const { siteDatas, etat = 0 } = d;
    if (etat === 200) {
      // console.log("connected with ajax");

      Store.siteDatas = siteDatas;
      this.connected = true;
      Events.fire("SOCKET_CONNECTED", true);
    } else {
      // Events.fire('SOCKET_ERROR', true );
      Events.fire("SOCKET_ERROR_TOTAL", true);
    }
  }

  async Reset() {
    this.uid = 0;
    this.socketid = "";
    this.keepAliveDelay = 30000; //30s
    this.connected = false;
    this.OnConnected = () => {};
    this.OnDisconnected = () => {};
  }
  KeepAlive() {}
}

const AjaxServer = new AjaxClass();
export { AjaxServer };
