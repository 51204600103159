export const scorecard = {
  helmetTitle: "VALUTAZIONE DELLE COMPETENZE - {$title}",
  BilanTest:
    'VALUTAZIONE DELLE <span class="prelative" >COMPETENZE {$logo} </span>',
  Company: "Organizzatore",
  GeneralInfos: "Informazioni generali",
  Level: "Livello",

  Responses: "Ripartizione delle tue risposte",
  GoodResponses: "Risposte giuste",
  BadResponses: "Risposte sbagliate",
  IDontKnow: "Non lo so",

  BestScore: "Meilleur Score",
  BadScore: "Moins Bon Score",

  TestDate: "Data di completamento",
  TestDuration: "Durata di completamento",
  YourScore: "IL TUO PUNTEGGIO",

  YourPlace: "Posizione del tuo punteggio",
  YourAverage: "Ripartizione dei punteggi per ogni competenza chiave",
  TempsPasses: "Rapporto tra punteggio e tempo dedicato al test",

  ShowAllAverage: "Afficher la moyenne des répondants",
  HideAllAverage: "Masquer la moyenne des répondants",

  OurRecommandations: "Conigli e risorse personalizzati",
  ConseilText:
    "Proovee vous aide à améliorer vos connaissances.<br />" +
    " Vous trouverez ci-dessous des ressources personnalisées pour chaque compétence pour le poste de {$nomposte} " +
    " chez {$company}.<br /> Articles, podcasts, glossaires, vidéos, livres blanc, formation… tout " +
    "un tas de ressources pour optimisez vos compétences.<br /> A consommer sans modération! ",
  AllAverage: "Media dei partecipanti",
  ShowRecommandation: "Leggi nostri consigli",

  YourClassement:
    "Vous vous classez dans le {$percent}% des candidats les mieux notés",
  YourClassementBest25:
    "Ti posizioni tra il 25% dei partecipanti con il punteggio miglior",
  YourClassementBest50:
    "Ti posizioni tra il 50% dei partecipanti con il punteggio miglior",
  YourClassementLess50:
    "Ti posizioni tra il 50% dei partecipanti con il punteggio più basso",
  YourClassementLess25:
    "Ti posizioni tra il 25% dei partecipanti con il punteggio più basso",

  NbQuestions: "Numero di domande",

  LegacyText:
    "<p>Proovee est  un partenaire de {$company} <br />" +
    "Et n'est pas responsable du recrutement.<br />" +
    "Nous aidons {$company} à qualifier les compétence des répondants<br />" +
    "pour décider de la suite à donner à votre candidature.</p>" +
    "<p>" +
    "Une question concernant ce test? Une suggestion à nous faire ?<br />" +
    '<a href="mailto:resultats@proovee.com" class="link-mail blank">resultats@proovee.com</a>' +
    "</p>",

  //'de  0 à 7', 'de 7 à 13', 'de 13 à 17', '17 et +'
  scoreCardRepatition_0_5: "from 0 to 5",
  scoreCardRepatition_5_10: "from 6 to 10",
  scoreCardRepatition_10_15: "from 11 to 15",
  scoreCardRepatition_15_X: "more 16",

  YouAreInThisGroup: "Sei in questo gruppo",
  RepartitionByScore: "Riparizione per punteggio",
  nbRepondants: "Numero di partecipanti",

  TempsRealisation: "tempo di completamento in minuti",
  scorerepondants: "Punteggi dei partecipanti",
  SocialFollow: "Seguici sui social networks",

  notFound: "Your result is not available",
  pleaseContactUs: "Please contact: ",
};
