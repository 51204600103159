import { CONFIG } from "core/config/Config.js";
export const Cache = {
  getItem: async (key) => {
    const data = await localStorage.getItem(CONFIG.APP_KEY + "_" + key);
    return { ...{}, ...JSON.parse(data) };
  },
  setItem: async (key, value) => {
    await localStorage.setItem(
      CONFIG.APP_KEY + "_" + key,
      JSON.stringify(value)
    );
    // console.log("save uuid", value);
    return true;
  },
  clear: () => {
    localStorage.clear();
  },
};
