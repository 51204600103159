import { CONFIG } from "core/config/Config.js";
import { WServer } from "../WServer/WServer.js";
import { Device } from "../Device/Device.js";
import { Store } from "../Store/Store.js";

class APIClass {
  constructor() {
    this.debug = false; //CONFIG.DEBUG;
  }

  async call(key, params = {}) {
    const { BUNDLE_VERSION = 1 } = CONFIG;
    const _params = {
      uuid: Device.UUID(),
      bundle_version: BUNDLE_VERSION,
      ...params,
      _device: Device.ToJSON(),
    };
    let _datas = false;
    _datas = await this.CallProcess(key, _params);
    return _datas;
  }
  CallProcess(key, _params) {
    const { api_mode = "socket" } = Store;
    if (api_mode === "socket") {
      return new Promise((resolve, reject) => {
        WServer.socket.emit(key, _params, (datas) => {
          // console.log(datas);
          resolve(datas);
        });
      });
    } else {
      return new Promise(async (resolve, reject) => {
        const result = await fetch("https://api.proovee.com/api/", {
          method: "POST",
          body: JSON.stringify({ path: key, datas: _params }),
          headers: { "Content-Type": "application/json" },
        });
        // console.log( result.json() );
        resolve(result.json());
      });
    }
  }
  CallProcessAjax(key, _params) {
    return new Promise((resolve, reject) => {
      WServer.socket.emit(key, _params, (datas) => {
        // console.log(datas);
        resolve(datas);
      });
    });
  }
  async Get(key, params = {}) {
    key = "get/" + key;
    return await this.call(key, params);
  }
  async Set(key, params = {}) {
    key = "set/" + key;
    return await this.call(key, params);
  }
  async Del(key, params = {}) {
    key = "del/" + key;
    return await this.call(key, params);
  }
}

const API = new APIClass();
export { API };
