/* eslint-disable */
import React, { useState, useCallback, useEffect } from "react";
import { CONFIG } from "core/config/Config.js";
import { InputForm } from "../Form/InputForm/InputForm.js";
import { CheckBox } from "../Form/CheckBox/CheckBox.js";
import { ProoveeLogo } from "../ProoveeLogo/ProoveeLogo.js";
import { SeparatorText } from "../SeparatorText/SeparatorText.js";
import { lg, Translate } from "core/locals/index.js";
import { API } from "../../lib/Api/Api.js";
//import { View, Text } from 'react-native';
//import { Colors, Styles, Store, Icon, lg, Users, RootUI, ModelCo } from 'core';
//import './LandingForm.ui.js';

import { LinkedInApi } from "../../lib/LinkedIn/LinkedIn.js";

import {
  Checkbox,
  FormControlLabel,
  Typography,
  Button,
  Link,
} from "@material-ui/core";

import TextField from "@material-ui/core/TextField";
import { LinkedInConnect } from "../LinkedInConnect/LinkedInConnect.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Actions } from "../Router/Router.js";

const CheckerBox = (props) => {
  const [checked, setCheck] = useState(true);
  const SetCheck = useCallback(async (e, c) => {
    const newCheck = !c ? true : false;
    // console.log( c, newCheck );
    await setCheck(newCheck);
    props.onChange(e);
  }, []);
  // console.log(checked);

  return (
    <Checkbox
      {...props}
      onChange={(e) => {
        SetCheck(e, checked);
      }}
      checked={checked}
    />
  );
};

// console.log("textes", Translate);

export class LandingForm extends React.Component {
  static defaultProps = {
    onBackClick: false,
    form: {},
  };
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      p: "",
    };
    this.refs = {
      cgu: false,
      email: false,
      firstname: false,
      lastname: false,
    };
    //super.LoadUI( 'LandingForm' );
  }
  // componentDidMount() {
  //   super.componentDidMount();
  //   this.setState({ loaded: true });
  //   console.log(this.props.form);
  // }
  render() {
    //
    return (
      <div className="flex postuler-form-block">
        <div className="postuler-landing-form">
          {this.renderBackBtn()}
          {this.renderContent()}
        </div>
      </div>
    );
  }
  renderBackBtn() {
    if (!this.props.onBackClick) return false;
    return (
      <span className="backarrow">
        <a onClick={() => this.props.onBackClick()}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </a>
      </span>
    );
  }

  renderContent() {
    var url =
      CONFIG.urls.currenthost +
      "/signin/linkedin" +
      "?redirect=" +
      window.location.href;
    if (this.state.p === "loading") return this.renderLoading();
    return (
      <div>
        <SeparatorText text={lg("landing.SignIn")} />
        <LinkedInConnect
          url={url}
          onLogued={(d) => {
            this.SignWithLinkedIn(d);
          }}
        />
        <SeparatorText text={lg("landing.OrUseYourMail")} />
        {this.renderForm()}
        <button
          className="cButton"
          onClick={() => {
            this.NextStep();
          }}
        >
          {lg("landing.next_steps").toUpperCase()}
        </button>
      </div>
    );
  }

  renderLoading() {
    return (
      <div className="loader">
        <i className="fas fa-spinner fa-spin"></i>
      </div>
    );
  }

  renderForm() {
    /*  var inputs = this.getInputs();
        var tmps = [];
        for( let  i in inputs ) {
            tmps.push( <div className="line-margin" key={"input-form-" + i } >
                <InputForm  datas={inputs[ i ]} ref={ e => {
                    var s = { };  s[ inputs[i].name  ] = e;
                    this.refs = {...this.refs , ...s };
                }} />
            </div>)
        }*/
    this.refs = {
      cgu: false,
      email: false,
      firstname: false,
      lastname: false,
    };
    // console.log( this.refs ); return false;
    const { cgu = {} } = this.refs;
    const { checked = true } = cgu;

    return (
      <div id="form">
        <div className="line-margin" key={"input-form-0"}>
          <InputForm
            datas={{
              label: lg("landing.firstname"),
              name: "firstname",
              value: "",
              require: true,
            }}
            ref={(e) => (this.refs.firstname = e)}
          />
        </div>
        <div className="line-margin" key={"input-form-1"}>
          <InputForm
            datas={{
              label: lg("landing.lastname"),
              name: "lastname",
              value: "",
              require: true,
            }}
            ref={(e) => (this.refs.lastname = e)}
          />
        </div>
        <div className="line-margin" key={"input-form-2"}>
          <InputForm
            datas={{
              label: lg("landing.Email"),
              name: "email",
              value: "",
              require: true,
              type: "email",
            }}
            ref={(e) => (this.refs.email = e)}
          />
        </div>

        <div className="form-line">
          <Typography
            variant="h6"
            style={{ color: "rgb( 100, 116, 139 )", fontSize: 12 }}
          >
            {lg("landing.VerifEmail")}
          </Typography>
        </div>

        <div className="form-line">
          <FormControlLabel
            control={
              <CheckerBox
                inputRef={(e) => (this.refs.cgu = e)}
                value="1"
                checked={checked}
                onChange={(e) => {
                  // console.log("check", e);

                  this.setState({ loaded: true });
                }}
                required={true}
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            }
            label={
              <Typography
                variant="h6"
                style={{ color: "rgb( 100, 116, 139 )", fontSize: 14 }}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: lg("landing.term_of_use"),
                  }}
                ></span>
              </Typography>
            }
          />
        </div>
      </div>
    );
  }

  getInputs() {
    var inputs = [];
    inputs.push({
      label: lg("landing.firstname"),
      name: "firstname",
      value: "",
      require: true,
    });
    inputs.push({
      label: lg("landing.lastname"),
      name: "lastname",
      value: "",
      require: true,
    });
    inputs.push({
      label: lg("landing.Email"),
      name: "email",
      value: "",
      require: true,
      type: "email",
    });
    return inputs;
  }

  async NextStep() {
    //control datas;
    // log(this.refs.cgu.checked);
    let errors = [];
    var fields = ["email", "firstname", "lastname"];
    for (let i in fields) {
      var f = fields[i];
      var c = await this.refs[f].Verif();
      // console.log( c )
      if (!c) {
        errors.push(f);
        this.refs[f].SetError(true);
      }
    }
    if (errors.length > 0) {
      if (errors.length === 1) {
        alert(lg("landing.OneFieldIncorrect"));
      } else {
        alert(lg("landing.SomeFieldsIncorrect", { x: errors.length }));
      }

      return false;
    }
    if (!this.refs.cgu.checked) {
      return false;
    }
    var email = await this.refs.email.Val();
    var nom = await this.refs.lastname.Val();
    var prenom = await this.refs.firstname.Val();
    const args = {
      email,
      nom,
      prenom,
    };
    //console.log( args ); return false;
    const d = await API.Get("postuler/talent/user/", { args });
    if (d.etat === 200) {
      // console.log(d.user);
      if (d.user !== false) {
        Actions.LandingFrame({
          user: { ...d.user, prenom, nom },
          form: this.props.form,
        });
      } else {
        const d2 = await API.Set("postuler/talent/user/", { args });
        if (d2.etat === 200) {
          Actions.LandingFrame({
            user: { ...d2.user, prenom, nom },
            form: this.props.form,
          });
        }
      }
    }
  }

  async SignWithLinkedIn({ code, redirect_uri }) {
    var args = {
      code,
      redirect_uri,
    };
    // console.log(args);
    this.setState({ p: "loading" });
    var datas = await API.Set("linkedin/signin/", { args });
    const { etat, user = false } = datas;
    // console.log(datas);
    if (etat === 200) {
      // console.log(user);
      if (user !== false) {
        Actions.LandingFrame({ user, form: this.props.form });
        this.setState({ p: "" });
      }
    }
    // const L = await new LinkedInApi();
    // await L.Init({ code, redirect_uri });
    // console.log(L.Me());
  }
}
