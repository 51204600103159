import React from "react";
import { Cache } from "./Cache/Cache.js";
import { Store } from "./Store/Store.js";
import { Events } from "./Events/Events.js";
import { Device } from "./Device/Device.js";
import { WServer } from "./WServer/WServer.js";
import { BrowserNav } from "./BrowserNav/index.js";
import { url_paths } from "core/config/Paths.js";
import { AjaxServer } from "./Ajax/Ajax.js";

export const Root = async () => {
  const { uuid = "" } = await Cache.getItem("UUID");
  if (uuid !== "") Store.uuid = uuid;
  if (uuid === "") {
    var query = "uuid=" + (await Device.UUID());
  } else {
    var query = "uuid=" + uuid;
  }

  BrowserNav._redirect_paths = url_paths;
  const c = await BrowserNav.ParseLocation();
  Store.CurrentNavPath = c;
  // console.log(Store.CurrentNavPath);
  const { api_mode } = Store;
  // console.log("Connection ROOT");
  if (api_mode === "socket") {
    Events.on("SOCKET_ERROR", "Root", () => {
      // console.log("Mode AJax");
      Store.api_mode = "ajax";
      AjaxServer.Connect({ uuid });
    });
    WServer.Connect({ query, uuid });
  } else {
    AjaxServer.Connect({ uuid });
  }
};
