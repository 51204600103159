/* eslint-disable */
import React from "react";
import axios from "axios";

import { WServer } from "./lib/WServer/WServer";
import { Events } from "./lib/Events/Events";
import { Device } from "./lib/Device/Device";
import { Cache } from "./lib/Cache/Cache";
import { API } from "./lib/Api/Api";

import { Router } from "./components/Router/Router.js";
import { Root } from "./lib/Root.js";
import { ProoveeLogo } from "./components/ProoveeLogo/ProoveeLogo";
import { ViewRoutes } from "./config/Routes.js";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loaded: false, error: 0 };
  }
  async load() {
    Events.on("SOCKET_CONNECTED", "APP", () => {
      // console.log("OK Connected");
      this.setState({ loaded: true });
      Events.OffEvents("SOCKET_ERROR");
      Events.OffEvents("SOCKET_ERROR_TOTAL");
    });
    Events.on("SOCKET_ERROR_TOTAL", "APP", () => {
      this.setState({ loaded: false, error: 1 });
      Events.OffEvents("SOCKET_CONNECTED");
    });
    Root();
  }
  componentDidMount() {
    this.load();
  }
  render() {
    if (!this.state.loaded) return this.Wait();
    return (
      <div className="flex">
        <Router routes={ViewRoutes}></Router>
      </div>
    );
  }
  Wait() {
    if (this.state.error > 0) return this.Error();
    return <div id="wait-home"></div>;
  }
  Error() {
    return (
      <div id="wait-home">
        <h4>
          If you don't have access, please contact :{" "}
          <a href="mailto:hello@proovee.com">hello@proovee.com</a>
        </h4>
      </div>
    );
  }
}

export default App;
