let Store = { }; 

const ResetStore = () =>  {
	Store =  {
	 
        
        DrawMenu: false,
		ModalLoader: false,
		ROUTES: [],
        queries: {},
		siteDatas: { },
		lang: false,
		CurrentNavPath: '', 
		api_mode: 'socket',
		//api_mode: 'ajax',
	}
}

ResetStore();
export { Store, ResetStore };