/* eslint-disable */
import React from "react";
import "./LinkedInConnect.css";

import { LinkedIn } from "react-linkedin-login-oauth2";
import linkedin from "react-linkedin-login-oauth2/assets/linkedin.png";
import { CONFIG } from "core/config/Config.js";

export class LinkedInConnect extends React.Component {
  static defaultProps = {
    url: "",
    onLogued: () => {},
  };
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }
  componentDidMount() {
    this.setState({ loaded: true });
    window._LinkedIn = (code) => {
      // console.log(code);
      this.props.onLogued({ code, redirect_uri: this.props.url });
    };
  }
  render() {
    var url = this.props.url;
    return (
      <div className="linkedInConnect-Container">
        <LinkedIn
          clientId="7874sbn4146ynl"
          onFailure={this.LinkedInFailed}
          onSuccess={this.LinkedInSuccessed}
          redirectUri={url}
          scope={["r_emailaddress", "r_liteprofile"]}
        >
          <div className="cButton  linkedIn "></div>
        </LinkedIn>
      </div>
    );
  }

  LinkedInFailed(e) {
    // console.log(e);
  }

  LinkedInSuccessed(e) {
    // console.log("success", e);
  }
}
