/* eslint-disable */
import React from "react";
import { CONFIG } from "core/config/Config.js";
import { lg } from "../../locals";
// import { View, Text } from 'react-native';
// import { Colors, Styles, Store, Icon, lg, Users, RootUI, ModelCo } from 'core';
// import './LandingCompany.ui.js';

import { Helmet } from "react-helmet";

export class LandingCompany extends React.Component {
  static defaultProps = {
    continuetBtn: false,
    form: {},
    onClick: () => {},
  };
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      company: {
        logo: false,
        //name: 'Texte en Gras',
        title: "Texte en Gras",
        description:
          " Treo helps developers to build organized and well-coded admin dashboards full of beautiful and feature rich modules.",
      },
      bgcolor: "rgb( 67, 56, 202 )",
      color: "#fff",
    };
    //super.LoadUI( 'LandingCompany' );
  }
  componentDidMount() {
    //super.componentDidMount();
    // this.setState({ loaded: true });
    this.LoadForm();
  }
  async LoadForm() {
    const { id = false } = this.props.form;
    if (id === false) {
      return false;
    }

    const { style = {}, logo = false } = this.props.form;

    const {
      title = "",
      description = "",
      bgcolor = this.state.bgcolor,
      color = this.state.color,
    } = style;
    var company = {
      title,
      description,
      bgcolor,
      color,
      logo,
    };
    this.setState({ loaded: true, company });
  }

  render() {
    if (!this.state.loaded) return <div />;
    const { bgcolor } = this.state.company;
    var title = lg("scorecard.helmetTitle", { title: this.props.form.title });
    return (
      <div
        className="flex postuler-company-block"
        style={{ backgroundColor: bgcolor }}
      >
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={title} />
        </Helmet>
        {this.renderCompanyText()}
      </div>
    );
  }
  renderCompanyLogo() {
    const { logo = false } = this.state.company;
    if (!logo) return <div />;
    var src = CONFIG.urls.images_recruiters + logo + ".png";
    return (
      <div id="logo-company">
        <img style={{ width: "100%" }} src={src} />
      </div>
    );
  }
  renderCompanyText() {
    const { title, description, color } = this.state.company;

    return (
      <div id="company-text-block">
        {this.renderCompanyLogo()}
        <h1 style={{ color }}>{title}</h1>
        <div
          className="company-text"
          style={{ color }}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        ></div>

        {this.renderContinueButton()}
      </div>
    );
  }
  renderContinueButton() {
    const { continuetBtn } = this.props;
    if (continuetBtn === false) return false;
    return (
      <div className="continue-button-container">
        <button
          className="cButton continue-button"
          onClick={() => {
            this.props.onClick();
          }}
        >
          {lg("landing.Participer")}
        </button>
      </div>
    );
  }
}
