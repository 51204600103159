import dateLocale from 'date-fns/locale/it';

import { moment as _moment } from './moment.js';
import { textes } from './textes.js';




export const it = {
    iso: 'it',
	textes,
	name: "Italia",
	_dateLocale: dateLocale,
	_moment
}