/* eslint-disable */
import React from "react";

/*** VIEW NOT FOUND */

import { Landing } from "components";
import { Device, Events } from "core/lib";
import { LandingSplitted } from "../../components/Landing/LandingSplitted";
import { API } from "core/lib/Api/Api.js";
import { empty } from "../../lib/Tools/Tools";
import { Actions } from "../../components";
import { Translate } from "../../locals";
import { Store } from "../../lib/Store/Store";
import { Helmet } from "react-helmet";

export class Postuler extends React.Component {
  static defaultProps = {
    token: "",
  };
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
    this.f = false;
    this.form = false;
  }
  componentDidMount() {
    // super.componentDidMount();
    this.LoadForm();
    //   this.setState({ loaded: true });
    Events.on("device-size-changed", "LandingSplitted", async () => {
      // await this.setState({ loaded: false });
      this.setState({ loaded: true });
    });
    // console.log(Events);
  }
  async LoadForm() {
    const { token, testMode = false } = this.props;

    if (empty(token)) return Actions.notfound();

    const args = { token };
    var { etat = 0, form = false } = await API.Get("form/", { args });
    if (etat === 200) {
      this.f = true;
      if (form.style === null) form.style = {};
      this.form = { lang: Store.lang, ...form, token };
      await Translate.SetLang(this.form.lang);
      if (testMode)
        return Actions.LandingFrame({ user: false, form, testMode });
      this.setState({ loaded: true });
    } else {
      return Actions.notfound();
    }
  }

  componentWillUnmount() {
    Events.off("device-size-changed", "LandingSplitted");
  }
  render() {
    if (this.f === false) return <div />;
    const { width } = Device.dimensions;
    if (width > 768) {
      return <Landing form={this.form} />;
    }
    return <LandingSplitted form={this.form} />;
  }
}
