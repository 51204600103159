import React from 'react';

import { Checkbox, FormControlLabel  , Typography, Button, Link } from '@material-ui/core';


export class CheckBox extends React.Component {
    static defaultProps = {
        className: '',
        label: '', value: '', 
        checked: false, 
        inputRef: false 
    } 
    constructor ( props ) {
        super( props );
        this.state = {
            value : this.props.value,
            checked: this.props.checked 
        }
        this._checkbox = React.createRef();
    }
    render() {
        const { className, label, value, checked  } = this.props; 
        if( this.state.checked ) {
            return this.renderChecked()
        } else {
            return this.renderUnChecked();
        }
    }

    renderChecked() {
        return (<CheckBox  
            inputRef={this.props.inputRef}
            value="1"
            checked={true}
            onChange={(e) => { 
               this.setState({ checked: false }); 
            }}
            required={true}
         color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
             />)
    } 

    renderUnChecked() {
        return (<CheckBox  
            inputRef={this.props.inputRef}
            value="1"
            checked={false}
            onChange={(e) => { 
               this.setState({ checked: true }); 
            }}
            required={true}
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
             />)
    }
}