export const scorecard = {
  helmetTitle: "BILAN DE COMPÉTENCES - {$title}",
  BilanTest: 'BILAN DE <span class="prelative" >COMPÉTENCES {$logo} </span>',
  Company: "Organisateur",
  GeneralInfos: "Informations générales",
  Level: "Niveau",

  Responses: "Répartition de vos réponses",
  GoodResponses: "Bonnes réponses",
  BadResponses: "Mauvaises réponses",
  IDontKnow: "Je ne sais pas",

  BestScore: "Meilleur Score",
  BadScore: "Moins Bon Score",

  TestDate: "Date de réalisation",
  TestDuration: "Durée de réalisation",
  YourScore: "VOTRE SCORE",

  YourPlace: "Positionnement de votre score",
  YourAverage: "Répartition des scores pour chaque compétence clé",
  TempsPasses: "Rapport entre le score et le temps passé sur le test",

  ShowAllAverage: "Afficher la moyenne des répondants",
  HideAllAverage: "Masquer la moyenne des répondants",

  OurRecommandations: "Conseils et ressources personnalisées",
  ConseilText:
    "Proovee vous aide à améliorer vos connaissances.<br />" +
    " Vous trouverez ci-dessous des ressources personnalisées pour chaque compétence pour le poste de {$nomposte} " +
    " chez {$company}.<br /> Articles, podcasts, glossaires, vidéos, livres blanc, formation… tout " +
    "un tas de ressources pour optimisez vos compétences.<br /> A consommer sans modération! ",
  AllAverage: "Moyenne des répondants",
  ShowRecommandation: "Lire nos conseils",

  YourClassement:
    "Vous vous classez dans le {$percent}% des candidats les mieux notés",
  YourClassementBest25:
    "vous vous classez dans les 25% des candidats les mieux notés",
  YourClassementBest50:
    "Vous vous classez dans les 50% des candidats les mieux notés",
  YourClassementLess50:
    "Vous vous classez dans les 50% des candidats les moins bien notés",
  YourClassementLess25:
    "Vous vous classez dans les 25% des candidats les moins bien notés ",

  NbQuestions: "Nombre de questions",

  LegacyText:
    "<p>Proovee est  un partenaire de {$company} <br />" +
    "Et n'est pas responsable du recrutement.<br />" +
    "Nous aidons {$company} à qualifier les compétence des répondants<br />" +
    "pour décider de la suite à donner à votre candidature.</p>" +
    "<p>" +
    "Une question concernant ce test? Une suggestion à nous faire ?<br />" +
    '<a href="mailto:resultats@proovee.com" class="link-mail blank">resultats@proovee.com</a>' +
    "</p>",

  //'de  0 à 7', 'de 7 à 13', 'de 13 à 17', '17 et +'
  scoreCardRepatition_0_5: "de 0 à 5",
  scoreCardRepatition_5_10: "de 6 à 10",
  scoreCardRepatition_10_15: "de 11 à 15",
  scoreCardRepatition_15_X: "Plus de 16",

  YouAreInThisGroup: "Vous êtes dans ce groupe",
  RepartitionByScore: "Répartition par notes",
  nbRepondants: "Nombre de répondants",

  TempsRealisation: "Temps de réalisation en minutes",
  scorerepondants: "Score des répondants",
  SocialFollow: "Suivez-nous sur les réseaux sociaux",

  notFound: "Votre résultat n'est pas disponible.",
  pleaseContactUs: "Merci de nous contacter: ",
};
