/* eslint-disable */
import moment from 'moment';

import { CONFIG } from 'core/config/index.js'
import { Store } from '../Store/Store.js';



var CryptoJS = require('crypto-js');
/** crypto  */
export const Crypt = async ( string )  => {
	var key = await CryptoJS.enc.Hex.parse(CONFIG.crypto.key);
	var iv  = await CryptoJS.enc.Hex.parse(CONFIG.crypto.iv);
	var encrypted = await CryptoJS.AES.encrypt( string , key, { iv: iv });
	var crypted_base64 =  await encrypted.ciphertext.toString(CryptoJS.enc.Base64);
	return crypted_base64;
}


export const array_chunk = (arr, count) => {
	var temp_arr = [];
	for(var i=0; i < arr.length; i++){
		var chunk_arr = [];
		for(var j=0; j<count; j++){
			if(!arr[i])  break;
			chunk_arr.push(arr[i]);
			i++;
		}
		temp_arr.push(chunk_arr);
	}
	return temp_arr;
}


export const Capitalize = (str) =>  {
	const c = str.toLowerCase();
	return c.charAt(0).toUpperCase() + c.slice(1)
}
export const CheckMail= ( email ) => {
	return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}
export const CheckPass = (password, maxLength = 6 , num = true, upper = true, sp = true  ) => {
	if( empty(password)) return false;
	if( password.length < maxLength ) return false;
	if (upper)
		if ( (password.match(/[A-Z]/g) || []).length  === 0 ) return false;
	if (num)
		if ( (password.match(/[0-9]/g) || []).length  === 0 ) return false;
	if ( (password.match(/[a-z]/g) || []).length  === 0 ) return false;
	if (sp )
		if ( (password.match(/[^a-zA-Z0-9]/g) || []).length  === 0 ) return false;
	return true;
}


export const empty = (value) => {
	/*if ( typeof value == 'undefined') return true;
	if (value == '') return true;
	if (parseInt(value, 10) == 'NaN') return true;
	if (parseInt(value, 10) == 0) return true;
	if (isNaN(value) && typeof value == 'number') return true;
	return false;*/
	if ( typeof value == 'undefined') return true;
	if ( typeof value === 'string') {
		if ( value === '' ) return true;
		var t = Number( value );
		
		if ( isNaN( t ) || t === 'NaN') {
			return false; 
		}
	}
	if( typeof value === 'array' ) {
		if ( value.length === 0 ) return true; 
		return false; 
	}
	var t = Number( value );
	if ( !isNaN( t )) {
		if( parseInt( t , 10 ) === 0 ) return true ;
		return false;
	}
	if( value === null ) return true;
	return false; 

}

export const GetNow = () =>  {
	return moment().format('YYYY-MM-DD HH:mm:ss');
}

export const InArray = (value, source = []) => {
	if (source.length == 0) return false;
	for( let i in source) {
		if (source[i] == value) return true;
	}
	return false;
}
/*** var type  */
export function IsArray( data ) {
	if( typeof  data === 'array') return true;
	false;
}
export  function IsJson(item) {
	item = typeof item !== "string"  ? JSON.stringify(item) : item;
	try {
		item = JSON.parse(item);
	} catch (e) {
		return false;
	}
	if (typeof item === "object" && item !== null) {
		return true;
	}
	return false;
}

export function IsNumber( data ) {
	if( typeof  data === 'number') return true;
	false;
}
export function IsObject(item) {
	return (item && typeof item === 'object' && !Array.isArray(item));
}
export function IsString( data ) {
	if( typeof  data === 'string') return true;
	false;
}



export const isset = ( value ) => {
    if( typeof value === 'undefined') return false;
    return true;
}
export const KeyValues = ( object ) => {
	for ( let i in object ) {
		return { key: i, value: object[i]};
	}
}


export const Sleep = ( ms ) => {
	return new Promise( res => setTimeout(res, ms) );
}
export const SplitText = ( text, nb = 4 , replaceTag = ' ' ) =>  {
	const pattern = new RegExp('.{1,'+nb+'}', 'g');
	const array = [ ...text.matchAll(pattern)] ;
	let tmp = [];
	for( let i in array ) {
		const c = array[i];
		tmp.push( c[0 ]) ;
	}
	return tmp.join( replaceTag );
}

export const strispslashes = (str ) => {
	if( typeof str === 'undefined') return ''; 
	var reg = new RegExp('\\\\', 'g');
	return str.replace( reg , '' ); 
}

export const IntRand = ( min, max ) => {
	return Math.floor(Math.random()*(max-min+1)+min);
}