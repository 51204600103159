/* eslint-disable */
import React from 'react';
import ReactCardFlip from 'react-card-flip' ; 
import { Card , CardContent, CardActionArea, Grid, Button  } from '@material-ui/core'; 
import Charts from 'react-apexcharts';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import './Skills.css';
import { lg } from '../../../locals/Translate';

export class Skills extends React.Component {
    static defaultProps = {
        skill: { }, 
    }
    constructor( props ) {
        super( props );
        this.state = {
            loaded: false,
            flipped: false ,
            all: false, 
        }
    }
    componentDidMount() {
        this.setState({ loaded: true });
    }
    render() {
       
        return(
            <ReactCardFlip  isFlipped={this.state.flipped}  >
                {this.recto()}
                {this.verso()}
            </ReactCardFlip>
        );
    }
    recto() {
        const { skill } = this.props;
        const { name = '', score = 0, average = 10, textes=  [ '', '' ] } = skill;
        var _name = name;
        if (typeof _name !== "string") {
            _name = _name.join(' '); 
        }
        /** display non if verso */
        var RectoStyle = { };
        if( this.state.flipped ) {
            RectoStyle = { display: 'none' }; 
        }
        

        return (
            <Card className="card" style={{ flexDirection: 'column', minHeight: 350, margin: 0 }} >
                <div className="card-top" >
                <div className="skill-donuts-container flex box-center" style={{ ...RectoStyle, height: 230 }}>
                       {this.renderCharts()}
                        <span className="donuts-value" >{score}
                        <span className="donuts-moyenne-value" style={{ display: ( this.state.all ? 'flex' : 'none') }} >
                            {average}
                        </span>
                        </span>
                        
                    </div>
                    
                    <div className="skill-moyenne-container">
                        <button className="show-moyenne" onClick={() => { this.showAverageToggle() }} >{lg('scorecard.AllAverage')}</button>
                    </div>
    
                </div>
                <CardContent className="card-content flex" style={{ ...RectoStyle }} >
                    <span className="card-label" >{_name}</span>
                    <div className="skill-description"  >
                        <div dangerouslySetInnerHTML={{__html: textes[0] }}>

                        </div>
                    </div>
                    <div className="button-container" >
                        <button className="skill-button" onClick={() => {  this.GoToVerso() ; }} >{lg('scorecard.ShowRecommandation')}</button>
                    </div>
                </CardContent>
            </Card>
        )
    }
    renderCharts() {
        const { skill } = this.props;
        const { name = '', score = 0, average = 10 } = skill;
        var sc  = parseInt( ( score / 20 ) * 100);
        var mc = parseInt( ( average / 20  ) * 100 ); 
        var data = [ sc  ];
        if( this.state.all ) data.push( mc ); 
        var options =  {
            chart: {
                sparkline: { enabled: true }, 
              type: 'radialBar',
              animations: {
                  enabled: true,
                  easing: 'easeinout',
                  speed: 800,
                  animateGradually: {
                      enabled: true,
                      delay: 150
                  },
              }
            },
            dataLabels: { enabled : false },
           tooltip: { enabled: false },
            legend: { show: false }, 
            responsive: [{
              breakpoint: 50000,
              options: {
               
                chart: {
                  width: 250,
                  
                }
              }
            }],
            plotOptions: {
                radialBar: {
                    dataLabels: { 
                        total: { show: false },
                        value: {
                            formatter: (val, e ) => { return '' }
                        },
                        name: { show: false}
                    }
                }
            }, 
            
            
            colors: [ '#1E2C4A', '#f66179',  '#CCC' ]
          } 
        if ( !this.state.loaded ) return false;
        return  <Charts options={options} series={data} type="radialBar" />; 

    }
    verso() {
        const { textes = [ '', '' ] } = this.props.skill; 
        AddBlank( 'card-content'); 
        return (
            <Card className="card" style={{ flexDirection: 'column', minHeight: 350, margin: 0 }}>
                <span className="skill-backarrow">
                    <a onClick={() => this.GoToRecto()} style={{ display: 'block' }}>
                        <FontAwesomeIcon  icon={faArrowLeft} />
                    </a>
                    
                </span>
                <CardContent className="card-content">
                    <div dangerouslySetInnerHTML={{__html: textes[1] }} >

                    </div>
                </CardContent>
            </Card>
        ); 
    }

    async GoToVerso() {
        this.setState({
            flipped: true
        });
    }

    async GoToRecto() {
        this.setState({
            flipped: false
        });
    }
    async showAverageToggle() {
        await this.setState({ loaded : false })
        var all = this.state.all;
        all = ( all ? false: true );
        this.setState({ all , loaded: true }); 
    }
}