/* eslint-disable */
import React from 'react';
import './InputForm.css';
import { CheckMail } from 'core/lib/Tools/Tools.js'; 



export class InputForm extends React.Component {
    static defaultProps = {
        className : '' ,
        datas: {   }

    }
    constructor( props ) {
        super( props );

        this._datas = {
            type: 'text',
            label: '',
            value: '',
            placeholder: '',
            require: false,
            ...this.props.datas
        };  
        this.state = {
            loaded: false,
            value: this._datas.value,
            error: false, 
        }
        this._input = React.createRef();
       
    }
    componentDidMount() {
    
    }
    render() {
        const { value } = this.state;
        const { className } = this.props; 
        const { label, placeholder, type,  require =  false   } = this._datas;
        var classes = ["InputForm", 'container' ];
        if( className !== '' ) classes.push( className );
        if ( this.state.error ) classes.push( 'error'); 

        return(
            <div className={classes.join(' ')}>
                <div className="content" >
                    <div className="inputContainer" >
                        <input className="input" type={type} 
                        placeholder={placeholder} value={value} ref={this._input} 
                        onChange={ ({ target }) => {
                            this.onChange( target )
                        }   } 
                        required={require}
                        />
                        <span className="label" >{label}{ require ? ' *' : '' }</span>
                    </div>

                </div>
                {this.renderError()}
            </div>
        );
    }
    renderError () {
        if( !this.state.error ) return false;
        return ( <span className="error_text"   >Error</span>)
    }
    async onChange( target ) {

        this.setState({ value: target.value, error: false  })


    }
    async SetError( error = false  ) {
        this.setState({ error }); 
    }
    async Verif() {
        var value = this.state.value;
        if ( value === '' && this._datas.require ) return false;
        if( this._datas.type === 'email' ) {
            if( !CheckMail( value )) return false;
        }


        return true;
    }
    async Val() {
        return this.state.value; 
    }

}