import React from 'react';
import './ScoreMedian.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faCaretUp} from '@fortawesome/free-solid-svg-icons'; 

export class ScoreMedian extends React.Component {
    static defaultProps = {
        data: { }
    }
    constructor( props ) {
        super( props );
        this.state = {
            loaded: false, 
        }
    }
    componentDidMount() {

        this.setState({ loaded: true });
    }
    render() {
        if( !this.state.loaded ) return false;
        return (<div className="ScoreMedian-container" >
            {this.renderMedian()}
            {this.renderScore()}
           
        </div>)
    }
    renderMedian() {
        return (
            <div className="ScoreMedian-MedianIcon" >
                <span style={{ fontSize: 36, margin: 10 }}>
                    <FontAwesomeIcon icon={faCaretUp} />
                </span>
                <span style={{ fontSize: 14, textAlign: 'center', marginTop: -20 }} >Médiane<br /> des répondants</span>
            </div>
        )
    }
    renderScore() {
        var score = 12; 
        var left = score + '%'
        

        return (<div className="ScoreMedian-MyScore" style={{ left}}>
                <div className="ScoreMedia-MyScore-Icon"  >

                </div>
                <span>
                    12 / 20
                </span>
        </div>)
    }
}

/*<div className="ScoreMedian-MedianIcon" >
                <span style={{ fontSize: 36 }}>
                    <FontAwesomeIcon icon={faCaretUp} />
                </span>
                <span>Médiane des répondants</span>
            </div>*/