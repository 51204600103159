/* eslint-disable */
import React from "react";

import ApexCharts from "apexcharts";
import Charts from "react-apexcharts";
import "./ScoreRadar.css";
import { lg } from "../../../locals/Translate";

export class ScoreRadar extends React.Component {
  static defaultProps = {
    datas: [],
    labels: [],

    data: {},
  };
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      options: {},
      datas: [],
      current: 0,
    };
  }
  componentDidMount() {
    const { labels } = this.props;
    var __labels = [];
    for (let i in labels) {
      var l = labels[i];
      i = parseInt(i);
      if (i === 0 || i === 3) {
        if (typeof l !== "string") l = l.join(" ");
      }
      __labels.push(l);
    }
    // console.log(__labels);
    var options = {
      chart: {
        id: "ScoreRadar",
        height: 350,
        type: "radar",
        zoom: {
          enabled: false,
        },
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1,
        },
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      title: {
        text: "",
      },
      stroke: {
        width: 2,
      },
      fill: {
        opacity: 0.1,
      },
      markers: {
        size: 0,
        colors: ["#1E2C4A", "#f66179"],
      },
      tooltip: {
        custom: function (d) {
          const { series, seriesIndex, dataPointIndex, w } = d;
          var label = labels[dataPointIndex];
          var value = series[seriesIndex][dataPointIndex];
          return '<div className="scoreradar-tooltip"  >' + label + "</div>";
        },
      },
      xaxis: {
        markers: {
          height: 20,
        },
        labels: {
          show: true,
          hideOverlappingLabels: true,
          style: {
            //colors: ["#FF0", "#CCC"],
          },
        },
        categories: __labels,
      },
      colors: ["#1E2C4A", "#f66179"],
    };
    var datas = [this.props.datas[0]];
    this.setState({ loaded: true, options, datas, current: 0 });
  }
  render() {
    if (this.state.loaded === false) return <div />;
    const { datas, options, current } = this.state;
    // console.log(datas);
    var btnLabel =
      current === 0
        ? lg("scorecard.ShowAllAverage")
        : lg("scorecard.HideAllAverage");
    return (
      <div>
        <Charts options={options} series={datas} type="radar" height={500} />
        <div className="align-center ">
          <button
            className="score-radar-button"
            onClick={() => {
              this.ShowToggle();
            }}
          >
            {btnLabel}
          </button>
        </div>
      </div>
    );
  }
  ShowToggle() {
    var current = this.state.current;
    if (current === 0) {
      this.ShowAllParticipants();
    } else {
      this.HideAllParticipants();
    }
  }
  ShowAllParticipants() {
    var datas = this.props.datas;
    this.setState({ datas, current: 1 });
  }
  HideAllParticipants() {
    var datas = [this.props.datas[0]];
    this.setState({ datas, current: 0 });
  }
}
