/* eslint-disable */
import { React, useState, useEffect } from "react";
import { CONFIG } from "core/config/Config.js";
import { Events } from "../Events/Events.js";
import { Store } from "../Store/Store.js";
import { Cache } from "../Cache/Cache.js";
import {
  deviceDetect,
  isMobile,
  mobileModel,
  osVersion,
  osName,
  mobileVendor,
} from "react-device-detect";

import packageJson from "../../../../package.json";
import { v4 as uuidv4 } from "uuid";

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  const orientation = width > height ? "LANDSCAPE" : "PORTRAIT";
  return {
    width,
    height,
    orientation,
  };
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

window.addEventListener("resize", () => {
  Events.fire("device-size-changed", getWindowDimensions());
});

class DeviceClass {
  constructor() {
    this.OS = osName;
    this.DeviceInfo = deviceDetect();
    this.model = this.getModel();
    this.dimensions = this.getDimensions();

    this.orientation = this.dimensions.orientation;
    Events.on("device-size-changed", "Device-Lib", (d) => {
      this.dimensions = d;
      this.orientation = this.dimensions.orientation;
    });
  }

  getDimensions() {
    return getWindowDimensions();
  }

  getModel() {
    // console.log(this.DeviceInfo);
    return "";
    // return this.DeviceInfo.getModel();
  }

  getOrientation() {
    const { width, height } = this.dimensions;
    if (width > height) return "LANDSCAPE";
    return "PORTRAIT";
  }
  getVersion() {
    return this.DeviceInfo.engineVersion;
  }
  /*hasNotch() {

    }*/
  IsAndroid() {
    return this.OS === "android" ? true : false;
  }
  IsMobile() {
    return isMobile;
  }
  IsIOS() {
    return this.OS === "ios" ? true : false;
  }

  ToJSON() {
    const { DeviceInfo } = this;
    return {
      version: packageJson.version,
      os: osName,
      os_version: osVersion,
      device_id: "",
      brand: mobileVendor,
      version_full: packageJson.version,
      //uuid: this.UUID(),
      //locale: DeviceInfo.getDeviceLocale(),
      app_bundle_version: this.getVersion() + "." + CONFIG.BUNDLE_VERSION,
    };
  }

  UUID() {
    if (typeof Store.uuid === "undefined") {
      Store.uuid = "web-" + uuidv4();
      Cache.setItem("UUID", { uuid: Store.uuid });
    }
    return Store.uuid;
  }
}

const Device = new DeviceClass();
export { Device, useWindowDimensions };
