/* eslint-disable */
import React from "react";
import { Helmet } from "react-helmet";
//import { View, Text } from 'react-native';
//import { Colors, Styles, Store, Icon, lg, User, RootUI, ModelCo } from 'core';
//import './Landing.ui.js';
import { LandingCompany, LandingForm } from "./index.js";
// import { Device } from "core/lib/Device/Device.js";
import "./Landing.css";
import { lg } from "../../locals/Translate.js";

export class Landing extends React.Component {
  static defaultProps = {
    form: {},
    onGoToFrame: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
    //super.LoadUI( 'Landing' );
  }

  componentDidMount() {
    // console.log("Device", Device);
    //super.componentDidMount();
    this.setState({ loaded: true });
  }

  render() {
    if (!this.state.loaded) return <div />;
    var title = lg("scorecard.helmetTitle", { title: this.props.form.title });
    return (
      <div id="Postuler" className="flex row">
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={title} />
        </Helmet>
        <LandingCompany form={this.props.form} />
        <LandingForm form={this.props.form} />
      </div>
    );
  }
}
