/* eslint-disable */
import React from "react";
import { empty } from "../../lib/Tools/Tools";

export class Signin extends React.Component {
  static defaultProps = {
    mode: "",
  };
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }
  componentDidMount() {
    this.setState({ loaded: true });
    // console.log(this.props);
    const { mode = "" } = this.props;
    if (empty(mode)) return false;
    if (mode === "linkedin") {
      this.linkedinReturn();
    }
  }
  render() {
    return <div id="signin-page"></div>;
  }

  async linkedinReturn() {
    const { redirect } = this.props;
    // if ( empty(  redirect ) ) return false;
    const { error = "", code = "" } = this.props;
    if (error === "unauthorized_scope_error") {
    }

    if (!empty(code)) {
      window.opener._LinkedIn(code);
      window.close();
    }

    //document.location.href= redirect;
  }
}
