import { CONFIG } from '../config/Config';
import { Store } from '../lib/Store/Store';
import { Translate, lg  } from './Translate';

//import langs here 
import { en } from './en';
import { fr } from './fr';
import { it } from './it'; 


const langs = {
    en,
    fr,
    it 
}


Translate.SetLangs( langs );
const { lang } = Store;
Translate.SetLang( lang );

export { lg, Translate  }; 