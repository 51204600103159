import moment from "moment";
import { Store } from "../lib/Store/Store.js";
import { CONFIG } from "../config/Config.js";
import HtmlParser from "html-react-parser";

if (Store.lang === false) Store.lang = CONFIG.lang;

class TranslateClass {
  constructor() {
    this.lang = Store.lang;
    this._datas = {};
  }
  SetLangs(languages) {
    this._datas = languages;
  }
  SetLang(iso, datas = false) {
    if (typeof iso === "undefined") iso = "en";
    Store.lang = iso.toLowerCase();
    if (datas !== false) {
      this._datas[iso] = datas;
    } else {
      if (typeof this._datas[iso] === "undefined") return false;
    }
    moment.locale(iso, this.GetMomentLocale(iso));
  }
  SetTexts(iso, textes) {
    if (typeof this._datas[iso] === "undefined") return false;
    this._datas[iso].textes = {
      ...this._datas[iso].textes,
      ...textes,
    };
  }
  GetDateLocale(iso) {
    if (typeof this._datas[iso] === "undefined") return false;
    return this._datas[iso]._dateLocale;
  }
  GetMomentLocale(iso) {
    if (typeof this._datas[iso] === "undefined") return false;
    return this._datas[iso]._moment;
  }
}
export const Translate = new TranslateClass();

export const lg = (path, props, html = false) => {
  const levels = path.split(".");
  const { lang = "fr" } = Store;
  if (typeof Translate._datas[lang] === "undefined") return "";
  if (typeof Translate._datas[lang].textes === "undefined") return "";
  let t = levels.reduce((prev, curr) => {
    return prev ? prev[curr] : undefined;
  }, Translate._datas[lang].textes);
  if (typeof props !== "undefined" && typeof t !== "undefined") {
    for (let i in props) {
      const find = new RegExp("\\{\\$" + i + "\\}", "g");
      t = t.replace(find, props[i]);
    }
  }

  /*if(props) {
		return interpolate(t, props);
	}*/
  if (html) return HtmlParser(t);
  return t;
};

export const DateLocale = () => {
  const { lang = "en" } = Store;
  return Translate.GetDateLocale(lang);
};
export const MomentLocale = () => {
  const { lang = "en" } = Store;
  var M = Translate.GetMomentLocale(lang);
  // console.log("MMM", Translate.GetMomentLocale("it"));
  return M;
};

export const Days = () => {
  //return Languages[lang].Days;
};
